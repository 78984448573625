import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiHost } from "./info";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
const SetFavorite = () => {
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState([]);
  const [favorite, SetFavorite] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios.get(ApiHost + "/category/all").then((res) => {
      if (res.data != "") {
        setData(res.data);
        console.log(res.data)
    
      }
    });

    await axios.get(ApiHost + "/favorite").then((res) => {
      if (res.data != "") {
        SetFavorite(res.data);
        console.log(res.data)
      }
    });
  };

  const onClickSet = async () => {
    const file = new FormData();
    checked.forEach((data, index) => {
      file.append("name[" + index + "]", data);
    });

    await axios
      .post(ApiHost + "/favorite", file, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        alert("成功");
      })
      .catch((e) => {
        alert("失敗");
      });

    getData();
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
          <br />
          4の倍数で選択してください。（4つづつ表示されるためデザインが崩れないように4の倍数）
          <br />
          最大枚数の設定はありませんがサイトが重くならないよう、16枚程度までを推奨致します。
          <br />
          ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
          <br />
          <Button onClick={(e) => onClickSet()}>登録</Button>
          {favorite != "" &&
            favorite.map((data, i) => <div>{data[0].name}</div>)}
        </Grid>
        <Grid item xs={6}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              overflow: "auto",
              maxHeight: "100vh",
              bgcolor: "background.paper",
            }}
          >
            {data.map((value, i) => (
              <ListItem key={i} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={(e) => handleToggle(value.name)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.name) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": i }}
                    />
                  </ListItemIcon>
                  <ListItemText id={i} primary={value.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default SetFavorite;
