import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HotelHost } from "./info";

const FunHotel = () => {
  const [cnt, setCnt] = useState("");
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setCnt("");
    await axios.get(HotelHost + "/count").then((res) => {
      if (res.data != "") {
        setCnt(res.data);
      }
    });
  };
  return (
    <div>
      <h3>
        <b>登録数：</b>
        {cnt}
      </h3>
      <p>
        <Link to="/CreateHotel">宿・ホテル登録</Link>
      </p>
      <p>
        <Link to="/EditHotel">宿・ホテル編集</Link>
      </p>
      <p>
        <Link to="/DeleteHotel">宿・ホテル削除</Link>
      </p>
      <p>
        <Link to="/VoteHotel">投票数確認</Link>
      </p>
      <p>
        <Link to="/VoteLogHotel">投票ログ確認</Link>
      </p>
      {/* <p>
        <Link to="/EditVoteHotel">投票数調整</Link>
      </p> */}
      <p>
        <Link to="/HotelEmail">メールアドレス確認</Link>
      </p>
    </div>
  );
};

export default FunHotel;
