import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { ApiHost, HotelHost, spas } from "./info";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const pre = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

const reg = ["北海道", "東北", "関東", "中部", "関西", "中国", "四国", "九州"];

const list = [
  "うる肌",
  "スポーツ・レジャー",
  "ファミリー",
  "リフレッシュ",
  "健康増進",
  "女子旅",
  "歴史・文化",
  "絶景",
  "ビジネスホテル",
  "リゾートホテル",
];

const category_list = [
  "uru",
  "sports",
  "family",
  "refresh",
  "health",
  "girl",
  "history",
  "view",
  "business",
  "resort",
];
const stick_name = [
  "なし",
  "貸切風呂",
  "展望風呂",
  "インフィニティ風呂",
  "サウナ",
  "夕食自慢",
  "朝食自慢",
  "ビュッフェ",
  "山の幸",
  "海の幸",
  "BBQ",
  "こだわりお酒",
  "マタニティ",
  "外国人おもてなし",
  "一人旅",
  "おこもり",
  "テレワーク",
  "バリアフリー",
  "ペット",
  "アクセス",
  "高級宿・ホテル",
];

const stick_list = [
  "no",
  "charter",
  "outlook",
  "infinity",
  "sauna",
  "dinner",
  "breakfast",
  "buffet",
  "mountain",
  "seafood",
  "bbq",
  "liquor",
  "maternity",
  "foreign",
  "travel",
  "recluse",
  "telework",
  "barrier",
  "pet",
  "access",
  "grade",
];

const rank_name = [
  "なし",
  "2022年全国１位",
  "2022年全国２位",
  "2022年全国３位",
  "2022年エリア１位",
  "2022年エリア２位",
  "2022年エリア３位",
];
const all_name = [
  "なし",
  "うる肌",
  "スポーツ・レジャー",
  "ファミリー",
  "リフレッシュ",
  "健康増進",
  "女子旅",
  "歴史・文化",
  "絶景",
  "ビジネスホテル",
  "リゾートホテル",
  "貸切風呂",
  "展望風呂",
  "インフィニティ風呂",
  "サウナ",
  "夕食自慢",
  "朝食自慢",
  "ビュッフェ",
  "山の幸",
  "海の幸",
  "BBQ",
  "こだわりお酒",
  "マタニティ",
  "外国人おもてなし",
  "一人旅",
  "おこもり",
  "テレワーク",
  "バリアフリー",
  "ペット",
  "アクセス",
  "高級宿・ホテル",
];
const EditHotel = () => {
  const [data, setData] = useState("");
  const [request, setRequest] = useState("");
  const [search, setSearch] = useState("");
  const [checked, setChecked] = useState([]);
  const [img, setImg] = useState(false);
  const [name, setName] = useState("");
  const [tell, setTell] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [access, setAccess] = useState("");
  const [address, setAddress] = useState("");
  const [category, setCategory] = useState("");
  const [stick1, setStick1] = useState(0);
  const [stick2, setStick2] = useState(0);
  const [stick3, setStick3] = useState(0);
  const [stick4, setStick4] = useState(0);
  const [body, setBody] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [main, setMain] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [google, setGoogle] = useState("");
  const [info1, setInfo1] = useState("");
  const [label1, setLabel1] = useState("");
  const [info2, setInfo2] = useState("");
  const [label2, setLabel2] = useState("");
  const [info3, setInfo3] = useState("");
  const [label3, setLabel3] = useState("");
  const [info4, setInfo4] = useState("");
  const [label4, setLabel4] = useState("");
  const [info5, setInfo5] = useState("");
  const [label5, setLabel5] = useState("");
  const [vote, setVote] = useState("");
  const [spa, setSpa] = useState("所属温泉なし");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [html, setHtml] = useState(null);
  const [batch1, setBatch1] = useState(0);
  const [batch2, setBatch2] = useState(0);
  const [batch3, setBatch3] = useState(0);
  const [batch4, setBatch4] = useState(0);
  const [batch_name1, setBatchName1] = useState(0);
  const [batch_name2, setBatchName2] = useState(0);
  const [batch_name3, setBatchName3] = useState(0);
  const [batch_name4, setBatchName4] = useState(0);
  const [area, setArea] = useState("北海道");
  const wrapper = {
    padding: "1rem",
    border: "1px solid #ccc",
  };
  const editor = {
    backgroundColor: "lightgray",
    padding: "1rem",
    border: "1px solid #ccc",
  };
  const toolbar = {
    border: "1px solid #ccc",
  };
  const onChangeEdit = () => {
    setHtml(convertToHTML(editorState.getCurrentContent()));
    setBody(editorState.getCurrentContent().getPlainText());
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setData("");
    setSearch("");
    await axios.get(HotelHost + "/hotel/all").then((res) => {
      if (res.data != "") {
        setData(res.data);
      }
    });
  };

  const requestData = async () => {
    await axios.get(HotelHost + "/hotel/all").then((res) => {
      if (res.data != "") {
        return setRequest(res.data);
      }
    });
  };

  const onClickUpdate = async () => {
    const file = new FormData();
    file.append("flg", img);
    file.append("name", name);
    file.append("tell", tell);
    file.append("prefecture", prefecture);
    file.append("access", access);
    file.append("address", address);
    file.append("category", category);
    file.append("stick1", stick1);
    file.append("stick2", stick2);
    file.append("stick3", stick3);
    file.append("stick4", stick4);
    file.append("body", body);
    file.append("html", html);
    file.append("instagram", instagram);
    file.append("twitter", twitter);
    file.append("facebook", facebook);
    file.append("youtube", youtube);
    file.append("tiktok", tiktok);
    file.append("google", google);
    file.append("main", main[0]);
    file.append("info1", info1);
    file.append("label1", label1);
    file.append("info2", info2);
    file.append("label2", label2);
    file.append("info3", info3);
    file.append("label3", label3);
    file.append("info4", info4);
    file.append("label4", label4);
    file.append("info5", info5);
    file.append("label5", label5);
    file.append("vote", vote);
    file.append("spa", spas.indexOf(spa));
    file.append("area", area);
    Array.from(thumbnail).forEach((d_file, index) => {
      file.append("thumbnail[" + index + "]", d_file);
    });

    file.append("batch1", batch1);
    file.append("batch2", batch2);
    file.append("batch3", batch3);
    file.append("batch4", batch4);
    file.append("batch_name1", batch_name1);
    file.append("batch_name2", batch_name2);
    file.append("batch_name3", batch_name3);
    file.append("batch_name4", batch_name4);

    await axios
      .post(HotelHost + "/hotel/" + checked.id, file, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        alert("成功");
      })
      .catch((e) => {
        alert("失敗");
      });
    setImg(false);
    setChecked([]);
    getData();
  };

  const handleToggle = (value) => {
    setChecked(value);
    setName(value.name);
    setAccess(value.access);
    setPrefecture(value.prefecture);
    setTell(value.tell);
    setAddress(value.address);
    setCategory(value.category);
    setStick1(value.stick1);
    setStick2(value.stick2);
    setStick3(value.stick3);
    setStick4(value.stick4);
    setVote(value.vote);
    if (value.spa != -1) setSpa(spas[value.spa]);
    else setSpa(0);
    setArea(value.area);
    setMain("");
    setThumbnail("");
    setBatch1(value.batch1);
    setBatch2(value.batch2);
    setBatch3(value.batch3);
    setBatch4(value.batch4);
    setBatchName1(value.batch_name1);
    setBatchName2(value.batch_name2);
    setBatchName3(value.batch_name3);
    setBatchName4(value.batch_name4);
    if (value.tell != null) {
      setTell(value.tell);
    } else {
      setTell("");
    }
    if (value.info1 != null && value.label1 != null) {
      setInfo1(value.info1);
      setLabel1(value.label1);
    } else {
      setInfo1("");
      setLabel1("");
    }
    if (value.info2 != null && value.label2 != null) {
      setInfo2(value.info2);
      setLabel2(value.label2);
    } else {
      setInfo2("");
      setLabel2("");
    }
    if (value.info3 != null && value.label3 != null) {
      setInfo3(value.info3);
      setLabel3(value.label3);
    } else {
      setInfo3("");
      setLabel3("");
    }
    if (value.info4 != null && value.label4 != null) {
      setInfo4(value.info4);
      setLabel4(value.label4);
    } else {
      setInfo4("");
      setLabel4("");
    }
    if (value.info5 != null && value.label5 != null) {
      setInfo5(value.info5);
      setLabel5(value.label5);
    } else {
      setInfo5("");
      setLabel5("");
    }
    if (value.google != null) {
      setGoogle(value.google);
    } else {
      setGoogle("");
    }
    if (value.tiktok != null) {
      setTiktok(value.tiktok);
    } else {
      setTiktok("");
    }
    if (value.instagram != null) {
      setInstagram(value.instagram);
    } else {
      setInstagram("");
    }
    if (value.twitter != null) {
      setTwitter(value.twitter);
    } else {
      setTwitter("");
    }
    if (value.facebook != null) {
      setFacebook(value.facebook);
    } else {
      setFacebook("");
    }
    if (value.youtube != null) {
      setYoutube(value.youtube);
    } else {
      setYoutube("");
    }
    if (value.access != null) {
      setAccess(value.access);
    } else {
      setAccess("");
    }
    if (value.body != null) {
      setBody(value.body);
    } else {
      setBody("");
    }
    console.log(value.html);
    if (value.html != null) {
      setHtml(value.html);
      setEditorState(() =>
        EditorState.createWithContent(convertFromHTML(value.html))
      );
    } else {
      setHtml("");
      setEditorState(() => EditorState.createEmpty());
    }
  };

  const onSearch = (value) => {
    setSearch(value);
    requestData();
    const Entry = [];
    for (var d of request) if (d.name.includes(value)) Entry.push(d);
    setData(Entry);
    if (value == "") getData();
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <p>
              宿・ホテル
              <input
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </p>
            <FormControl sx={{ m: 1, minWidth: 80, width: "35%" }} size="small">
              <InputLabel>投票参加</InputLabel>
              <Select
                label="投票参加"
                value={vote}
                onChange={(e) => setVote(e.target.value)}
              >
                <MenuItem key={1} value={1}>
                  参加する
                </MenuItem>
                <MenuItem key={0} value={0}>
                  参加しない
                </MenuItem>
              </Select>
            </FormControl>
            <p>
              <div>都道府県</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "25%" }}
                size="small"
              >
                <InputLabel>都道府県</InputLabel>
                <Select
                  label="都道府県"
                  value={prefecture}
                  onChange={(e) => setPrefecture(e.target.value)}
                >
                  {pre.map((pre, i) => (
                    <MenuItem key={pre} value={pre}>
                      {pre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <div>地方</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "15%" }}
                size="small"
              >
                <InputLabel>地方</InputLabel>
                <Select
                  label="地方"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                >
                  {reg.map((reg, i) => (
                    <MenuItem key={reg} value={reg}>
                      {reg}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <div>温泉地</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "25%" }}
                size="small"
              >
                <InputLabel>温泉地</InputLabel>
                <Select
                  native
                  label="温泉地"
                  value={spa}
                  onChange={(e) => setSpa(e.target.value)}
                >
                  <option value="その他">その他</option>
                  <option value="所属温泉なし">所属温泉なし</option>
                  <optgroup label="北海道">
                    <option value="阿寒湖温泉">阿寒湖温泉</option>
                    <option value="旭岳温泉">旭岳温泉</option>
                    <option value="朝里川温泉">朝里川温泉</option>
                    <option value="芦別温泉">芦別温泉</option>
                    <option value="網走湖畔温泉郷">網走湖畔温泉郷</option>
                    <option value="岩尾別温泉">岩尾別温泉</option>
                    <option value="ウトロ温泉">ウトロ温泉</option>
                    <option value="エルム高原温泉ゆったり">
                      エルム高原温泉ゆったり
                    </option>
                    <option value="雄阿寒温泉">雄阿寒温泉</option>
                    <option value="帯広温泉郷">帯広温泉郷</option>
                    <option value="オムイ温泉">オムイ温泉</option>
                    <option value="温根湯温泉">温根湯温泉</option>
                    <option value="神威脇温泉保養所">神威脇温泉保養所</option>
                    <option value="カルルス温泉">カルルス温泉</option>
                    <option value="川湯温泉">川湯温泉</option>
                    <option value="北村温泉">北村温泉</option>
                    <option value="北湯沢温泉">北湯沢温泉</option>
                    <option value="木野温泉">木野温泉</option>
                    <option value="霧多布温泉">霧多布温泉</option>
                    <option value="剣淵温泉">剣淵温泉</option>
                    <option value="支笏湖温泉">支笏湖温泉</option>
                    <option value="岬の湯しゃこたん">岬の湯しゃこたん</option>
                    <option value="定山渓温泉">定山渓温泉</option>
                    <option value="知内温泉旅館ユートピア和楽園">
                      知内温泉旅館ユートピア和楽園
                    </option>
                    <option value="ススキノ温泉">ススキノ温泉</option>
                    <option value="層雲峡温泉">層雲峡温泉</option>
                    <option value="天塩川温泉">天塩川温泉</option>
                    <option value="天人峡温泉">天人峡温泉</option>
                    <option value="洞爺湖温泉">洞爺湖温泉</option>
                    <option value="十勝川温泉">十勝川温泉</option>
                    <option value="十勝岳温泉">十勝岳温泉</option>
                    <option value="常呂川温泉">常呂川温泉</option>
                    <option value="苫小牧温泉">苫小牧温泉</option>
                    <option value="とままえ温泉">とままえ温泉</option>
                    <option value="豊浦温泉">豊浦温泉</option>
                    <option value="ニセコ温泉郷">ニセコ温泉郷</option>
                    <option value="ぬかびら源泉郷">ぬかびら源泉郷</option>
                    <option value="登別温泉">登別温泉</option>
                    <option value="函館温泉">函館温泉</option>
                    <option value="函館大沼温泉">函館大沼温泉</option>
                    <option value="函館南かやべ大船温泉">
                      函館南かやべ大船温泉
                    </option>
                    <option value="びえい白金温泉">びえい白金温泉</option>
                    <option value="平磯温泉">平磯温泉</option>
                    <option value="吹上温泉">吹上温泉</option>
                    <option value="妹背牛温泉ペペル">妹背牛温泉ペペル</option>
                    <option value="湯の川温泉">湯の川温泉</option>
                    <option value="養老牛温泉">養老牛温泉</option>
                    <option value="稚内中央温泉">稚内中央温泉</option>
                    <option value="遠別町旭温泉">遠別町旭温泉</option>
                    <option value="中標津温泉">中標津温泉</option>
                    <option value="小樽">小樽</option>
                    <option value="ヤムワッカナイ温泉港のゆ">
                      ヤムワッカナイ温泉港のゆ
                    </option>
                    <option value="びらとり温泉　ゆから">
                      びらとり温泉　ゆから
                    </option>
                    <option value="知内温泉">知内温泉</option>
                    <option value="こもれび温泉">こもれび温泉</option>
                    <option value="しょさんべつ温泉">しょさんべつ温泉</option>
                    <option value="いわない温泉">いわない温泉</option>
              <option value="大手町源泉">大手町源泉</option>
                  </optgroup>

                  <optgroup label="青森">
                    <option value="浅虫温泉">浅虫温泉</option>
                    <option value="奥入瀬渓流温泉">奥入瀬渓流温泉</option>
                    <option value="大鰐温泉">大鰐温泉</option>
                    <option value="追子野木久米源泉">追子野木久米源泉</option>
                    <option value="古牧温泉">古牧温泉</option>
                    <option value="下風呂温泉">下風呂温泉</option>
                    <option value="田代温泉">田代温泉</option>
                    <option value="朔日町源泉">朔日町源泉</option>
                    <option value="薬研温泉">薬研温泉</option>
                    <option value="八甲田山九湯会">八甲田山九湯会</option>
                    <option value="十和田湖畔温泉">十和田湖畔温泉</option>
                    <option value="馬門温泉">馬門温泉</option>
                    <option value="岩木山温泉郷（嶽温泉、百沢温泉など）">
                      岩木山温泉郷（嶽温泉、百沢温泉など）
                    </option>
                  </optgroup>

                  <optgroup label="岩手">
                    <option value="一関温泉郷">一関温泉郷</option>
                    <option value="奥州平泉温泉">奥州平泉温泉</option>
                    <option value="鶯宿温泉">鶯宿温泉</option>
                    <option value="大船渡温泉">大船渡温泉</option>
                    <option value="金ヶ崎温泉郷">金ヶ崎温泉郷</option>
                    <option value="夏油高原温泉郷">夏油高原温泉郷</option>
                    <option value="瀬美温泉">瀬美温泉</option>
                    <option value="つなぎ温泉">つなぎ温泉</option>
                    <option value="とぴや温泉">とぴや温泉</option>
                    <option value="永岡温泉">永岡温泉</option>
                    <option value="八幡平市温泉郷">八幡平市温泉郷</option>
                    <option value="花巻温泉郷">花巻温泉郷</option>
                    <option value="平泉温泉">平泉温泉</option>
                    <option value="みちのく城址温泉">みちのく城址温泉</option>
                    <option value="湯田温泉峡">湯田温泉峡</option>
                    <option value="鉛温泉 藤三旅館">鉛温泉 藤三旅館</option>
                    <option value="花巻温泉">花巻温泉</option>
                    <option value="台温泉">台温泉</option>
                    <option value="金矢温泉">金矢温泉</option>
                    <option value="松倉温泉">松倉温泉</option>
                    <option value="渡り温泉">渡り温泉</option>
                    <option value="山の神温泉">山の神温泉</option>
                    <option value="新鉛温泉">新鉛温泉</option>
                    <option value="しゃくなげの湯っこ　五葉温泉">
                      しゃくなげの湯っこ　五葉温泉
                    </option>
                  </optgroup>

                  <optgroup label="宮城">
                    <option value="青根温泉">青根温泉</option>
                    <option value="秋保温泉">秋保温泉</option>
                    <option value="小原温泉郷">小原温泉郷</option>
                    <option value="鎌先温泉">鎌先温泉</option>
                    <option value="作並温泉">作並温泉</option>
                    <option value="遠刈田温泉">遠刈田温泉</option>
                    <option value="鳴子温泉郷">鳴子温泉郷</option>
                    <option value="太古天泉・松島温泉">
                      太古天泉・松島温泉
                    </option>
                    <option value="やくらい高原温泉">やくらい高原温泉</option>
                    <option value="鳴子温泉">鳴子温泉</option>
                    <option value="東鳴子温泉">東鳴子温泉</option>
                    <option value="川渡温泉">川渡温泉</option>
                    <option value="中山平温泉">中山平温泉</option>
                    <option value="鬼首温泉">鬼首温泉</option>
                    <option value="気仙沼温泉">気仙沼温泉</option>
                    <option value="松島温泉">松島温泉</option>
                    <option value="やくらい薬師の湯">やくらい薬師の湯</option>
                    <option value="陶芸の里ゆ～らんど">
                      陶芸の里ゆ～らんど
                    </option>
                    <option value="鳴子温泉">鳴子温泉</option>
                  </optgroup>

                  <optgroup label="秋田">
                    <option value="秋田八幡平温泉郷">秋田八幡平温泉郷</option>
                    <option value="秋の宮温泉">秋の宮温泉</option>
                    <option value="大湯温泉">大湯温泉</option>
                    <option value="男鹿温泉郷">男鹿温泉郷</option>
                    <option value="小安峡温泉">小安峡温泉</option>
                    <option value="仙北温泉">仙北温泉</option>
                    <option value="たしろ温泉">たしろ温泉</option>
                    <option value="田代温泉">田代温泉</option>
                    <option value="中里温泉">中里温泉</option>
                    <option value="南郷温泉">南郷温泉</option>
                    <option value="乳頭温泉郷">乳頭温泉郷</option>
                    <option value="湯瀬温泉">湯瀬温泉</option>
                    <option value="玉川温泉">玉川温泉</option>
                    <option value="水沢温泉郷">水沢温泉郷</option>
                    <option value="砂丘温泉ゆめろん">砂丘温泉ゆめろん</option>
                    <option value="森岳温泉郷">森岳温泉郷</option>
              <option value="田尻湖高原温泉">田尻湖高原温泉</option>
                  </optgroup>

                  <optgroup label="山形">
                    <option value="赤倉温泉">赤倉温泉</option>
                    <option value="赤湯温泉">赤湯温泉</option>
                    <option value="あつみ温泉">あつみ温泉</option>
                    <option value="大石田温泉">大石田温泉</option>
                    <option value="奥おおえ柳川温泉">奥おおえ柳川温泉</option>
                    <option value="小野川温泉">小野川温泉</option>
                    <option value="月山志津温泉">月山志津温泉</option>
                    <option value="かみのやま温泉">かみのやま温泉</option>
                    <option value="銀山温泉">銀山温泉</option>
                    <option value="黒鴨温泉滝の湯">黒鴨温泉滝の湯</option>
                    <option value="蔵王温泉">蔵王温泉</option>
                    <option value="寒河江温泉">寒河江温泉</option>
                    <option value="さくらんぼ東根温泉">
                      さくらんぼ東根温泉
                    </option>
                    <option value="白布温泉">白布温泉</option>
                    <option value="瀬見温泉">瀬見温泉</option>
                    <option value="鷹野湯温泉">鷹野湯温泉</option>
                    <option value="天童温泉">天童温泉</option>
                    <option value="なの花温泉">なの花温泉</option>
                    <option value="白鷹温泉">白鷹温泉</option>
                    <option value="肘折温泉">肘折温泉</option>
                    <option value="ひまわり温泉ゆらら">
                      ひまわり温泉ゆらら
                    </option>
                    <option value="舟唄温泉">舟唄温泉</option>
                    <option value="湯田川温泉">湯田川温泉</option>
                    <option value="湯野浜温泉">湯野浜温泉</option>
                    <option value="由良温泉">由良温泉</option>
                  </optgroup>

                  <optgroup label="福島">
                    <option value="会津芦ノ牧温泉">会津芦ノ牧温泉</option>
                    <option value="会津東山温泉">会津東山温泉</option>
                    <option value="会津湯野上温泉">会津湯野上温泉</option>
                    <option value="熱塩温泉">熱塩温泉</option>
                    <option value="穴原温泉">穴原温泉</option>
                    <option value="飯坂温泉">飯坂温泉</option>
                    <option value="猪苗代温泉">猪苗代温泉</option>
                    <option value="いわき藤間温泉">いわき藤間温泉</option>
                    <option value="いわき湯本温泉">いわき湯本温泉</option>
                    <option value="岩瀬湯本温泉">岩瀬湯本温泉</option>
                    <option value="表磐梯温泉">表磐梯温泉</option>
                    <option value="甲子温泉">甲子温泉</option>
                    <option value="新甲子温泉">新甲子温泉</option>
                    <option value="高湯温泉">高湯温泉</option>
                    <option value="岳温泉">岳温泉</option>
                    <option value="土湯温泉">土湯温泉</option>
                    <option value="天栄温泉">天栄温泉</option>
                    <option value="那須白河温泉">那須白河温泉</option>
                    <option value="沼尻温泉">沼尻温泉</option>
                    <option value="猫啼温泉">猫啼温泉</option>
                    <option value="羽鳥湖温泉">羽鳥湖温泉</option>
                    <option value="磐梯熱海温泉">磐梯熱海温泉</option>
                    <option value="磐梯山清水平温泉">磐梯山清水平温泉</option>
                    <option value="磐梯横向温泉">磐梯横向温泉</option>
                    <option value="二岐温泉">二岐温泉</option>
                    <option value="宮下温泉">宮下温泉</option>
                    <option value="深沢温泉">深沢温泉</option>
                    <option value="あぶくま温泉">あぶくま温泉</option>
                    <option value="会津若松">会津若松</option>
                  </optgroup>

                  <optgroup label="茨城">
                    <option value="磯原温泉">磯原温泉</option>
                    <option value="五浦温泉">五浦温泉</option>
                    <option value="大洗温泉">大洗温泉</option>
                    <option value="奥久慈温泉郷">奥久慈温泉郷</option>
                    <option value="筑波温泉">筑波温泉</option>
                    <option value="手綱温泉">手綱温泉</option>
                    <option value="屛風ヶ浦温泉">屛風ヶ浦温泉</option>
                    <option value="平潟港温泉">平潟港温泉</option>
                    <option value="潮来水原温泉">潮来水原温泉</option>
                  </optgroup>

                  <optgroup label="栃木">
                    <option value="板室温泉">板室温泉</option>
                    <option value="大田原温泉">大田原温泉</option>
                    <option value="川治温泉">川治温泉</option>
                    <option value="川俣温泉">川俣温泉</option>
                    <option value="喜連川温泉">喜連川温泉</option>
                    <option value="⿁怒川温泉">⿁怒川温泉</option>
                    <option value="黒羽温泉五峰の湯">黒羽温泉五峰の湯</option>
                    <option value="塩原温泉">塩原温泉</option>
                    <option value="中禅寺温泉">中禅寺温泉</option>
                    <option value="那須温泉">那須温泉</option>
                    <option value="日光温泉">日光温泉</option>
                    <option value="日光湯元温泉">日光湯元温泉</option>
                    <option value="湯津上温泉やすらぎの湯">
                      湯津上温泉やすらぎの湯
                    </option>
                    <option value="湯西川温泉">湯西川温泉</option>
                    <option value="霧降高原">霧降高原</option>
                    <option value="馬頭温泉郷">馬頭温泉郷</option>
                    <option value="奥日光湯元温泉">奥日光湯元温泉</option>
                    <option value="塩原温泉郷">塩原温泉郷</option>
                    <option value="那須温泉郷">那須温泉郷</option>
                  </optgroup>

                  <optgroup label="群馬">
                    <option value="赤城温泉">赤城温泉</option>
                    <option value="伊香保温泉">伊香保温泉</option>
                    <option value="磯部温泉">磯部温泉</option>
                    <option value="猪田温泉絹の湯">猪田温泉絹の湯</option>
                    <option value="上野村温泉郷">上野村温泉郷</option>
                    <option value="老神温泉">老神温泉</option>
                    <option value="奥軽井沢温泉">奥軽井沢温泉</option>
                    <option value="鹿沢温泉">鹿沢温泉</option>
                    <option value="片品温泉郷">片品温泉郷</option>
                    <option value="上牧温泉">上牧温泉</option>
                    <option value="川原湯温泉">川原湯温泉</option>
                    <option value="桐生新里温泉">桐生新里温泉</option>
                    <option value="草津温泉">草津温泉</option>
                    <option value="四万温泉">四万温泉</option>
                    <option value="尻焼温泉">尻焼温泉</option>
                    <option value="嬬恋バラギ温泉">嬬恋バラギ温泉</option>
                    <option value="藤岡温泉">藤岡温泉</option>
                    <option value="万座温泉">万座温泉</option>
                    <option value="みなかみ18湯">みなかみ18湯</option>
                    <option value="本白根温泉">本白根温泉</option>
                    <option value="薬師温泉">薬師温泉</option>
                    <option value="川場温泉">川場温泉</option>
                    <option value="浜平温泉・しおじの湯">
                      浜平温泉・しおじの湯
                    </option>
                    <option value="みなかみ18湯　谷川温泉">
                      みなかみ18湯　谷川温泉
                    </option>
              <option value="鬼押温泉">鬼押温泉</option>
                  </optgroup>

                  <optgroup label="埼玉">
                    <option value="小鹿野温泉">小鹿野温泉</option>
                    <option value="金山温泉">金山温泉</option>
                    <option value="柴原温泉">柴原温泉</option>
                    <option value="秩父大滝温泉三峰神の湯秩父七湯鳩の湯温泉">
                      秩父大滝温泉三峰神の湯秩父七湯鳩の湯温泉
                    </option>
                    <option value="秩父吉田温泉・星音の湯">
                      秩父吉田温泉・星音の湯
                    </option>
                    <option value="和銅鉱泉">和銅鉱泉</option>
                  </optgroup>

                  <optgroup label="千葉">
                    <option value="旭九十九里温泉">旭九十九里温泉</option>
                    <option value="一宮温泉">一宮温泉</option>
                    <option value="犬吠埼温泉">犬吠埼温泉</option>
                    <option value="岩井湯元温泉">岩井湯元温泉</option>
                    <option value="亀山温泉">亀山温泉</option>
                    <option value="鴨川温泉">鴨川温泉</option>
                    <option value="小湊温泉">小湊温泉</option>
                    <option value="白子温泉">白子温泉</option>
                    <option value="館山温泉郷">館山温泉郷</option>
                    <option value="千倉温泉郷">千倉温泉郷</option>
                    <option value="⾧生温泉">⾧生温泉</option>
                    <option value="南房総白浜温泉郷">南房総白浜温泉郷</option>
                    <option value="安房温泉">安房温泉</option>
                    <option value="養老渓谷温泉">養老渓谷温泉</option>
                    <option value="ラジャ舞浜温泉">ラジャ舞浜温泉</option>
                    <option value="南房総温泉">南房総温泉</option>
                    <option value="館山温泉郷">館山温泉郷</option>
                  </optgroup>

                  <optgroup label="東京">
                    <option value="秋川渓谷・瀬音の湯">
                      秋川渓谷・瀬音の湯
                    </option>
                    <option value="浅草観音温泉">浅草観音温泉</option>
                    <option value="数馬の湯">数馬の湯</option>
                    <option value="亀島川温泉">亀島川温泉</option>
                    <option value="東京江東温泉">東京江東温泉</option>
                    <option value="八日市場温泉あ・うんの湯">
                      八日市場温泉あ・うんの湯
                    </option>
                    <option value="羽田空港 泉天空温泉">
                      羽田空港 泉天空温泉
                    </option>
                    <option value="有明　泉天空温泉">有明　泉天空温泉</option>
                    <option value="青梅石神温泉">青梅石神温泉</option>
                  </optgroup>

                  <optgroup label="神奈川">
                    <option value="川崎東田町温泉">川崎東田町温泉</option>
                    <option value="中川温泉">中川温泉</option>
                    <option value="東丹沢七沢温泉郷">東丹沢七沢温泉郷</option>
                    <option value="箱根温泉">箱根温泉</option>
                    <option value="三浦マホロバ温泉">三浦マホロバ温泉</option>
                    <option value="湯河原温泉">湯河原温泉</option>
                    <option value="飯山温泉郷">飯山温泉郷</option>
                    <option value="南足柄温泉">南足柄温泉</option>
                    <option value="箱根湯本温泉">箱根湯本温泉</option>
                    <option value="強羅温泉">強羅温泉</option>
                    <option value="箱根小涌谷温泉">箱根小涌谷温泉</option>
                    <option value="箱根仙石原">箱根仙石原</option>
                  </optgroup>

                  <optgroup label="新潟">
                    <option value="赤倉温泉">赤倉温泉</option>
                    <option value="池の平温泉">池の平温泉</option>
                    <option value="糸魚川温泉">糸魚川温泉</option>
                    <option value="岩室温泉">岩室温泉</option>
                    <option value="鵜の浜温泉">鵜の浜温泉</option>
                    <option value="越後湯沢温泉">越後湯沢温泉</option>
                    <option value="大湯温泉">大湯温泉</option>
                    <option value="貝掛温泉">貝掛温泉</option>
                    <option value="麒麟山温泉">麒麟山温泉</option>
                    <option value="結東温泉">結東温泉</option>
                    <option value="五頭温泉郷">五頭温泉郷</option>
                    <option value="咲花温泉">咲花温泉</option>
                    <option value="佐渡加茂湖温泉">佐渡加茂湖温泉</option>
                    <option value="新赤倉温泉">新赤倉温泉</option>
                    <option value="新胎内温泉">新胎内温泉</option>
                    <option value="杉野沢温泉">杉野沢温泉</option>
                    <option value="関温泉">関温泉</option>
                    <option value="瀬波温泉">瀬波温泉</option>
                    <option value="鷹ノ巣温泉">鷹ノ巣温泉</option>
                    <option value="月岡温泉">月岡温泉</option>
                    <option value="燕温泉">燕温泉</option>
                    <option value="栃尾又温泉">栃尾又温泉</option>
                    <option value="舞子温泉">舞子温泉</option>
                    <option value="柵口温泉">柵口温泉</option>
                    <option value="松之山温泉">松之山温泉</option>
                    <option value="丸山温泉">丸山温泉</option>
                    <option value="妙高温泉">妙高温泉</option>
                    <option value="六日町温泉">六日町温泉</option>
                    <option value="安田温泉">安田温泉</option>
                    <option value="弥彦温泉">弥彦温泉</option>
                    <option value="湯田上温泉">湯田上温泉</option>
                    <option value="大崎温泉">大崎温泉</option>
                    <option value="じょんのび村温泉">じょんのび村温泉</option>
                    <option value="塩の道温泉ホテルホワイトクリフ">
                      塩の道温泉ホテルホワイトクリフ
                    </option>
                    <option value="松之山温泉">松之山温泉</option>
                  </optgroup>

                  <optgroup label="富山">
                    <option value="生地温泉">生地温泉</option>
                    <option value="宇奈月温泉">宇奈月温泉</option>
                    <option value="越中つるぎ温泉">越中つるぎ温泉</option>
                    <option value="黒部峡谷温泉群">黒部峡谷温泉群</option>
                    <option value="庄川温泉郷">庄川温泉郷</option>
                    <option value="富山温泉">富山温泉</option>
                    <option value="能登半島国定公園氷見温泉郷">
                      能登半島国定公園氷見温泉郷
                    </option>
                    <option value="宮島温泉">宮島温泉</option>
                    <option value="法林寺温泉">法林寺温泉</option>
                  </optgroup>

                  <optgroup label="石川">
                    <option value="粟津温泉">粟津温泉</option>
                    <option value="岩間温泉">岩間温泉</option>
                    <option value="片山津温泉">片山津温泉</option>
                    <option value="金沢無量寺温泉">金沢無量寺温泉</option>
                    <option value="小松温泉">小松温泉</option>
                    <option value="白峰温泉">白峰温泉</option>
                    <option value="珠洲温泉">珠洲温泉</option>
                    <option value="辰口温泉">辰口温泉</option>
                    <option value="千里浜温泉郷">千里浜温泉郷</option>
                    <option value="白山一里野温泉">白山一里野温泉</option>
                    <option value="松任CCZ温泉">松任CCZ温泉</option>
                    <option value="山代温泉">山代温泉</option>
                    <option value="山中温泉">山中温泉</option>
                    <option value="湯涌温泉">湯涌温泉</option>
                    <option value="和倉温泉">和倉温泉</option>
                    <option value="輪島温泉郷">輪島温泉郷</option>
                    <option value="能登黒川温泉">能登黒川温泉</option>
                  </optgroup>

                  <optgroup label="福井">
                    <option value="あわら温泉">あわら温泉</option>
                    <option value="越前くりや温泉">越前くりや温泉</option>
                    <option value="越前水仙の里温泉">越前水仙の里温泉</option>
                    <option value="勝山温泉">勝山温泉</option>
                    <option value="三国温泉">三国温泉</option>
                    <option value="みかた温泉">みかた温泉</option>
                    <option value="越前玉川温泉">越前玉川温泉</option>
                    <option value="越前南部温泉">越前南部温泉</option>
                    <option value="花みずき温泉">花みずき温泉</option>
                    <option value="越前糸生温泉">越前糸生温泉</option>
                    <option value="別所新加賀温泉">別所新加賀温泉</option>
                    <option value="芦原温泉">芦原温泉</option>
                  </optgroup>

                  <optgroup label="山梨">
                    <option value="赤石温泉">赤石温泉</option>
                    <option value="欽明温泉">欽明温泉</option>
                    <option value="石和温泉">石和温泉</option>
                    <option value="春日居温泉">春日居温泉</option>
                    <option value="下部温泉">下部温泉</option>
                    <option value="積翠寺温泉">積翠寺温泉</option>
                    <option value="富士河口湖温泉郷">富士河口湖温泉郷</option>
                    <option value="真木温泉">真木温泉</option>
                    <option value="万葉温泉">万葉温泉</option>
                    <option value="みはらしの丘みたまの湯">
                      みはらしの丘みたまの湯
                    </option>
                    <option value="桃の木温泉">桃の木温泉</option>
                    <option value="山中湖温泉">山中湖温泉</option>
                    <option value="湯村温泉">湯村温泉</option>
                    <option value="竜王ラドン温泉">竜王ラドン温泉</option>
                    <option value="増富温泉">増富温泉</option>
                  </optgroup>

                  <optgroup label="長野">
                    <option value="秋山郷">秋山郷</option>
                    <option value="浅間温泉">浅間温泉</option>
                    <option value="飯田城温泉">飯田城温泉</option>
                    <option value="美ヶ原温泉">美ヶ原温泉</option>
                    <option value="大町温泉郷">大町温泉郷</option>
                    <option value="奥蓼科温泉">奥蓼科温泉</option>
                    <option value="小布施温泉">小布施温泉</option>
                    <option value="鹿教湯温泉">鹿教湯温泉</option>
                    <option value="釜沼温泉">釜沼温泉</option>
                    <option value="上高地温泉">上高地温泉</option>
                    <option value="上諏訪温泉">上諏訪温泉</option>
                    <option value="軽井沢の温泉">軽井沢の温泉</option>
                    <option value="久米川温泉">久米川温泉</option>
                    <option value="さわんど温泉">さわんど温泉</option>
                    <option value="志賀高原の温泉">志賀高原の温泉</option>
                    <option value="下諏訪温泉">下諏訪温泉</option>
                    <option value="白樺湖温泉">白樺湖温泉</option>
                    <option value="白骨温泉">白骨温泉</option>
                    <option value="蓼科温泉">蓼科温泉</option>
                    <option value="戸狩温泉">戸狩温泉</option>
                    <option value="戸倉上山田温泉">戸倉上山田温泉</option>
                    <option value="扉温泉">扉温泉</option>
                    <option value="中尾山温泉">中尾山温泉</option>
                    <option value="南木曽温泉郷">南木曽温泉郷</option>
                    <option value="野沢温泉">野沢温泉</option>
                    <option value="乗鞍高原温泉">乗鞍高原温泉</option>
                    <option value="白馬姫川温泉">白馬姫川温泉</option>
                    <option value="昼神温泉">昼神温泉</option>
                    <option value="別所温泉">別所温泉</option>
                    <option value="穂高温泉郷">穂高温泉郷</option>
                    <option value="馬曲温泉">馬曲温泉</option>
                    <option value="湯田中渋温泉郷">湯田中渋温泉郷</option>
                    <option value="松代温泉">松代温泉</option>
                    <option value="白馬八方温泉">白馬八方温泉</option>
              <option value="南木曽温泉">南木曽温泉</option>
                  </optgroup>

                  <optgroup label="岐阜">
                    <option value="池田さくら温泉">池田さくら温泉</option>
                    <option value="恵那峡温泉">恵那峡温泉</option>
                    <option value="奥飛騨温泉郷">奥飛騨温泉郷</option>
                    <option value="⿁岩温泉">⿁岩温泉</option>
                    <option value="郡上温泉">郡上温泉</option>
                    <option value="下呂温泉">下呂温泉</option>
                    <option value="白川郷">白川郷</option>
                    <option value="白川郷平瀬温泉">白川郷平瀬温泉</option>
                    <option value="渡合温泉">渡合温泉</option>
                    <option value="⾧良川温泉">⾧良川温泉</option>
                    <option value="濁河温泉">濁河温泉</option>
                    <option value="飛騨高山温泉">飛騨高山温泉</option>
                    <option value="平湯温泉">平湯温泉</option>
                    <option value="湯屋温泉">湯屋温泉</option>
                    <option value="彦根千乃松原温泉">彦根千乃松原温泉</option>
                  </optgroup>

                  <optgroup label="静岡">
                    <option value="網代温泉">網代温泉</option>
                    <option value="熱川温泉">熱川温泉</option>
                    <option value="熱海温泉">熱海温泉</option>
                    <option value="天城湯ヶ島温泉郷">天城湯ヶ島温泉郷</option>
                    <option value="伊豆高原温泉">伊豆高原温泉</option>
                    <option value="伊豆⾧岡温泉">伊豆⾧岡温泉</option>
                    <option value="伊東温泉">伊東温泉</option>
                    <option value="稲取温泉">稲取温泉</option>
                    <option value="今井浜温泉">今井浜温泉</option>
                    <option value="梅ヶ島温泉">梅ヶ島温泉</option>
                    <option value="大川温泉">大川温泉</option>
                    <option value="掛川温泉">掛川温泉</option>
                    <option value="片瀬・白田温泉">片瀬・白田温泉</option>
                    <option value="河津温泉">河津温泉</option>
                    <option value="川根温泉">川根温泉</option>
                    <option value="舘山寺温泉">舘山寺温泉</option>
                    <option value="木太刀の湯">木太刀の湯</option>
                    <option value="雲見温泉">雲見温泉</option>
                    <option value="静岡まちなか温泉">静岡まちなか温泉</option>
                    <option value="下賀茂温泉">下賀茂温泉</option>
                    <option value="下田温泉">下田温泉</option>
                    <option value="修善寺温泉">修善寺温泉</option>
                    <option value="寸又峡温泉">寸又峡温泉</option>
                    <option value="土肥温泉">土肥温泉</option>
                    <option value="堂ヶ島温泉">堂ヶ島温泉</option>
                    <option value="畑毛温泉">畑毛温泉</option>
                    <option value="松崎温泉">松崎温泉</option>
                    <option value="三島温泉">三島温泉</option>
                    <option value="三ヶ日温泉">三ヶ日温泉</option>
                    <option value="焼津温泉">焼津温泉</option>
                    <option value="雄踏温泉">雄踏温泉</option>
                    <option value="湯ヶ島高原温泉">湯ヶ島高原温泉</option>
                    <option value="弓ヶ浜温泉">弓ヶ浜温泉</option>
                    <option value="油山温泉">油山温泉</option>
                    <option value="下田・相玉温泉">下田・相玉温泉</option>
                    <option value="宇佐美温泉">宇佐美温泉</option>
                    <option value="熱海伊豆山温泉">熱海伊豆山温泉</option>
                    <option value="河津七滝温泉・大滝温泉">
                      河津七滝温泉・大滝温泉
                    </option>
                    <option value="御殿場温泉">御殿場温泉</option>
                    <option value="接岨峡温泉">接岨峡温泉</option>
              <option value= "峰温泉">峰温泉</option>
                  </optgroup>

                  <optgroup label="愛知">
                    <option value="犬山温泉">犬山温泉</option>
                    <option value="尾張温泉郷">尾張温泉郷</option>
                    <option value="蒲郡温泉郷">蒲郡温泉郷</option>
                    <option value="吉良温泉">吉良温泉</option>
                    <option value="笹戸温泉">笹戸温泉</option>
                    <option value="猿投温泉">猿投温泉</option>
                    <option value="豊川市本宮温泉">豊川市本宮温泉</option>
                    <option value="西浦温泉">西浦温泉</option>
                    <option value="南知多温泉郷">南知多温泉郷</option>
                    <option value="湯谷温泉">湯谷温泉</option>
                    <option value="伊良湖温泉">伊良湖温泉</option>
                    <option value="みはま温泉">みはま温泉</option>
                  </optgroup>

                  <optgroup label="三重">
                    <option value="伊勢二見の温泉">伊勢二見の温泉</option>
                    <option value="榊原温泉">榊原温泉</option>
                    <option value="志摩の温泉">志摩の温泉</option>
                    <option value="玉城弘法温泉">玉城弘法温泉</option>
                    <option value="鳥羽温泉郷">鳥羽温泉郷</option>
                    <option value="トマト温泉">トマト温泉</option>
                    <option value="中伊勢温泉郷">中伊勢温泉郷</option>
                    <option value="長島温泉">長島温泉</option>
                    <option value="浜島温泉">浜島温泉</option>
                    <option value="湯の山温泉">湯の山温泉</option>
                    <option value="奥伊勢宮川温泉">奥伊勢宮川温泉</option>
                    <option value="鳥羽">鳥羽</option>
                    <option value="本浦温泉">本浦温泉</option>
                    <option value="潮香の湯">潮香の湯</option>
                  </optgroup>

                  <optgroup label="滋賀">
                    <option value="おごと温泉">おごと温泉</option>
                    <option value="彦根千乃松原温泉">彦根千乃松原温泉</option>
                  </optgroup>

                  <optgroup label="京都">
                    <option value="浅茂川浦島温泉">浅茂川浦島温泉</option>
                    <option value="天橋立温泉">天橋立温泉</option>
                    <option value="嵐山温泉">嵐山温泉</option>
                    <option value="大原温泉">大原温泉</option>
                    <option value="木津温泉">木津温泉</option>
                    <option value="久美の浜温泉郷">久美の浜温泉郷</option>
                    <option value="丹後温泉">丹後温泉</option>
                    <option value="鳴き砂温泉">鳴き砂温泉</option>
                    <option value="能勢アートレイク温泉">
                      能勢アートレイク温泉
                    </option>
                    <option value="ひよし温泉">ひよし温泉</option>
                    <option value="宮津温泉">宮津温泉</option>
                    <option value="夕日ヶ浦温泉">夕日ヶ浦温泉</option>
                    <option value="湯の花温泉">湯の花温泉</option>
                    <option value="るり渓温泉">るり渓温泉</option>
                  </optgroup>

                  <optgroup label="大阪">
                    <option value="天見温泉">天見温泉</option>
                    <option value="生駒西村温泉">生駒西村温泉</option>
                    <option value="犬鳴山温泉">犬鳴山温泉</option>
                    <option value="牛滝温泉">牛滝温泉</option>
                    <option value="奥水間温泉">奥水間温泉</option>
                    <option value="能勢温泉">能勢温泉</option>
                    <option value="伏尾温泉">伏尾温泉</option>
                    <option value="松葉温泉滝の湯">松葉温泉滝の湯</option>
                    <option value="箕面温泉">箕面温泉</option>
                    <option value="富田林嶽山温泉２号泉">
                      富田林嶽山温泉２号泉
                    </option>
                  </optgroup>

                  <optgroup label="兵庫">
                    <option value="赤穂温泉">赤穂温泉</option>
                    <option value="有馬温泉">有馬温泉</option>
                    <option value="淡路島の温泉">淡路島の温泉</option>
                    <option value="香住温泉郷">香住温泉郷</option>
                    <option value="城崎温泉">城崎温泉</option>
                    <option value="こうでら温泉">こうでら温泉</option>
                    <option value="神戸二軒茶屋温泉">神戸二軒茶屋温泉</option>
                    <option value="神戸みなと温泉">神戸みなと温泉</option>
                    <option value="塩田温泉">塩田温泉</option>
                    <option value="太山寺温泉">太山寺温泉</option>
                    <option value="宝塚・武田尾温泉">宝塚・武田尾温泉</option>
                    <option value="竹野温泉">竹野温泉</option>
                    <option value="ハチ北温泉">ハチ北温泉</option>
                    <option value="浜坂温泉郷">浜坂温泉郷</option>
                    <option value="円山川温泉">円山川温泉</option>
                    <option value="湯村温泉">湯村温泉</option>
                    <option value="赤穂御崎温泉">赤穂御崎温泉</option>
                    <option value="愛宕山泉源">愛宕山泉源</option>
                    <option value="小倉山温泉">小倉山温泉</option>
                  </optgroup>

                  <optgroup label="奈良">
                    <option value="大宇陀温泉あきののゆ">
                      大宇陀温泉あきののゆ
                    </option>
                    <option value="大峯山洞川温泉">大峯山洞川温泉</option>
                    <option value="天然温泉 朱雀の湯">天然温泉 朱雀の湯</option>
                    <option value="十津川温泉郷（十津川温泉・湯泉地温泉・上湯温泉）">
                      十津川温泉郷（十津川温泉・湯泉地温泉・上湯温泉）
                    </option>
                    <option value="奈良宇陀温泉郷">奈良宇陀温泉郷</option>
                    <option value="平城宮温泉">平城宮温泉</option>
                    <option value="大和平群温泉">大和平群温泉</option>
                    <option value="吉野山温泉">吉野山温泉</option>
                    <option value="みつえ温泉 姫石の湯">
                      みつえ温泉 姫石の湯
                    </option>
                    <option value="美榛温泉">美榛温泉</option>
                    <option value="洞川温泉地">洞川温泉地</option>
                    <option value="曽爾高原温泉　お亀の湯">
                      曽爾高原温泉　お亀の湯
                    </option>
                  </optgroup>

                  <optgroup label="和歌山">
                    <option value="紀州有田川温泉">紀州有田川温泉</option>
                    <option value="かなや明恵峡温泉">かなや明恵峡温泉</option>
                    <option value="熊野本宮温泉郷">熊野本宮温泉郷</option>
                    <option value="紀伊見温泉">紀伊見温泉</option>
                    <option value="紀州温泉">紀州温泉</option>
                    <option value="白浜温泉">白浜温泉</option>
                    <option value="天神温泉">天神温泉</option>
                    <option value="南紀勝浦温泉">南紀勝浦温泉</option>
                    <option value="花山温泉">花山温泉</option>
                    <option value="やどり温泉いやしの湯">
                      やどり温泉いやしの湯
                    </option>
                    <option value="湯の峰温泉">湯の峰温泉</option>
                    <option value="龍神温泉">龍神温泉</option>
                    <option value="龍門山温泉">龍門山温泉</option>
                    <option value="渡瀬温泉">渡瀬温泉</option>
                    <option value="南紀串本">南紀串本</option>
                    <option value="和歌の浦温泉">和歌の浦温泉</option>
                  </optgroup>

                  <optgroup label="鳥取">
                    <option value="岩井温泉">岩井温泉</option>
                    <option value="皆生温泉">皆生温泉</option>
                    <option value="鹿野温泉">鹿野温泉</option>
                    <option value="関金温泉">関金温泉</option>
                    <option value="東郷温泉">東郷温泉</option>
                    <option value="鳥取温泉">鳥取温泉</option>
                    <option value="浜村温泉">浜村温泉</option>
                    <option value="はわい温泉">はわい温泉</option>
                    <option value="三朝温泉">三朝温泉</option>
                    <option value="吉岡温泉">吉岡温泉</option>
                  </optgroup>

                  <optgroup label="島根">
                    <option value="旭温泉">旭温泉</option>
                    <option value="有福温泉">有福温泉</option>
                    <option value="風の国温泉">風の国温泉</option>
                    <option value="さぎの湯温泉">さぎの湯温泉</option>
                    <option value="三瓶山温泉郷 飯南エリア">
                      三瓶山温泉郷 飯南エリア
                    </option>
                    <option value="三瓶温泉">三瓶温泉</option>
                    <option value="大社温泉">大社温泉</option>
                    <option value="玉造温泉">玉造温泉</option>
                    <option value="斐乃上温泉">斐乃上温泉</option>
                    <option value="松江しんじ湖温泉">松江しんじ湖温泉</option>
                    <option value="美又温泉">美又温泉</option>
                    <option value="出雲湯の川温泉">出雲湯の川温泉</option>
                    <option value="温泉津温泉">温泉津温泉</option>
                    <option value="湯屋温泉">湯屋温泉</option>
                    <option value="飯南町健康増進施設　加田の湯">
                      飯南町健康増進施設　加田の湯
                    </option>
                  </optgroup>

                  <optgroup label="岡山">
                    <option value="奥津温泉">奥津温泉</option>
                    <option value="瀬戸内温泉たまの湯">
                      瀬戸内温泉たまの湯
                    </option>
                    <option value="湯郷温泉">湯郷温泉</option>
                    <option value="湯原温泉郷">湯原温泉郷</option>
                    <option value="グリーンミュージアム神郷温泉">
                      グリーンミュージアム神郷温泉
                    </option>
                    <option value="新見千屋温泉　いぶきの里">
                      新見千屋温泉　いぶきの里
                    </option>
                  </optgroup>

                  <optgroup label="広島">
                    <option value="宮島・宮浜の温泉">宮島・宮浜の温泉</option>
                    <option value="きのえ温泉">きのえ温泉</option>
                    <option value="君田温泉">君田温泉</option>
                    <option value="庄原さくら温泉">庄原さくら温泉</option>
                    <option value="鞆の浦温泉">鞆の浦温泉</option>
                    <option value="湯来温泉">湯来温泉</option>
                    <option value="龍頭温泉">龍頭温泉</option>
                    <option value="宮浜温泉">宮浜温泉</option>
                    <option value="宮島温泉">宮島温泉</option>
                    <option value="湯の山温泉">湯の山温泉</option>
                  </optgroup>

                  <optgroup label="山口">
                    <option value="笠戸島大城温泉">笠戸島大城温泉</option>
                    <option value="片添ヶ浜温泉">片添ヶ浜温泉</option>
                    <option value="川棚温泉">川棚温泉</option>
                    <option value="菊川温泉">菊川温泉</option>
                    <option value="俵山温泉">俵山温泉</option>
                    <option value="⾧門湯本温泉">⾧門湯本温泉</option>
                    <option value="萩温泉郷">萩温泉郷</option>
                    <option value="光室積温泉">光室積温泉</option>
                    <option value="湯田温泉">湯田温泉</option>
                    <option value="油谷湾温泉">油谷湾温泉</option>
                  </optgroup>

                  <optgroup label="徳島">
                    <option value="大歩危・祖谷温泉郷">
                      大歩危・祖谷温泉郷
                    </option>
                    <option value="四季美谷温泉">四季美谷温泉</option>
                    <option value="もみじ川温泉">もみじ川温泉</option>
                    <option value="わじき温泉">わじき温泉</option>
                  </optgroup>

                  <optgroup label="香川">
                    <option value="絹島温泉ベッセルおおちの湯">
                      絹島温泉ベッセルおおちの湯
                    </option>
                    <option value="こんぴら温泉郷">こんぴら温泉郷</option>
                    <option value="塩江温泉">塩江温泉</option>
                    <option value="小豆島の温泉">小豆島の温泉</option>
                    <option value="高松クレーターの湯天然温泉きらら">
                      高松クレーターの湯天然温泉きらら
                    </option>
                    <option value="ニューレオマ天然温泉">
                      ニューレオマ天然温泉
                    </option>
                    <option value="母神温泉">母神温泉</option>
                    <option value="薬師湯温泉">薬師湯温泉</option>
                    <option value="讃岐丸亀">讃岐丸亀</option>
                    <option value="京極の湯">京極の湯</option>
                  </optgroup>

                  <optgroup label="愛媛">
                    <option value="奥道後温泉">奥道後温泉</option>
                    <option value="鹿野川温泉">鹿野川温泉</option>
                    <option value="道後温泉">道後温泉</option>
                    <option value="鈍川温泉">鈍川温泉</option>
                    <option value="湯ノ浦温泉">湯ノ浦温泉</option>
                    <option value="東温市ふるさと交流館さくらの湯">
                      東温市ふるさと交流館さくらの湯
                    </option>
                    <option value="大洲市交流促進センター鹿野川荘">
                      大洲市交流促進センター鹿野川荘
                    </option>
                    <option value="見奈良温泉">見奈良温泉</option>
                  </optgroup>

                  <optgroup label="高知">
                    <option value="あしずり温泉郷">あしずり温泉郷</option>
                    <option value="郷麓温泉">郷麓温泉</option>
                    <option value="木の香温泉">木の香温泉</option>
                    <option value="長岡温泉">長岡温泉</option>
                    <option value="ながおか温泉">ながおか温泉</option>
                    <option value="仁淀川伊野温泉">仁淀川伊野温泉</option>
                    <option value="中津渓谷　ゆの森">中津渓谷　ゆの森</option>
                  </optgroup>

                  <optgroup label="福岡">
                    <option value="赤村ふるさとセンター源じいの森温泉">
                      赤村ふるさとセンター源じいの森温泉
                    </option>
                    <option value="筑後川温泉">筑後川温泉</option>
                    <option value="原鶴温泉">原鶴温泉</option>
                    <option value="二日市温泉">二日市温泉</option>
                    <option value="船小屋温泉郷">船小屋温泉郷</option>
                    <option value="奥おおえ 柳川温泉">奥おおえ 柳川温泉</option>
                    <option value="若松ひびき温泉">若松ひびき温泉</option>
                    <option value="脇田温泉">脇田温泉</option>
                    <option value="柳川温泉">柳川温泉</option>
                    <option value="王丸温泉　宗像王丸・天然温泉やまつばさ">
                      王丸温泉　宗像王丸・天然温泉やまつばさ
                    </option>
                  </optgroup>

                  <optgroup label="佐賀">
                    <option value="嬉野温泉">嬉野温泉</option>
                    <option value="唐津温泉">唐津温泉</option>
                    <option value="北方温泉">北方温泉</option>
                    <option value="武雄温泉">武雄温泉</option>
                    <option value="たら竹崎温泉">たら竹崎温泉</option>
                    <option value="古湯･熊の川温泉">古湯･熊の川温泉</option>
                    <option value="きゅうらぎ温泉　佐用姫の湯">
                      きゅうらぎ温泉　佐用姫の湯
                    </option>
                  </optgroup>

                  <optgroup label="長崎">
                    <option value="壱岐湯ノ本温泉">壱岐湯ノ本温泉</option>
                    <option value="稲佐山温泉">稲佐山温泉</option>
                    <option value="雲仙温泉">雲仙温泉</option>
                    <option value="小浜温泉">小浜温泉</option>
                    <option value="島原温泉">島原温泉</option>
                    <option value="そのぎ茶温泉">そのぎ茶温泉</option>
                    <option value="⾧崎ゆりの温泉">⾧崎ゆりの温泉</option>
                    <option value="野母崎温泉">野母崎温泉</option>
                    <option value="平戸温泉">平戸温泉</option>
                    <option value="平戸たびら温泉">平戸たびら温泉</option>
                    <option value="佐世保">佐世保</option>
                  </optgroup>

                  <optgroup label="熊本">
                    <option value="阿蘇内牧温泉">阿蘇内牧温泉</option>
                    <option value="植木温泉">植木温泉</option>
                    <option value="小田温泉">小田温泉</option>
                    <option value="上天草温泉郷">上天草温泉郷</option>
                    <option value="菊池温泉">菊池温泉</option>
                    <option value="黒川温泉">黒川温泉</option>
                    <option value="佐俣の湯">佐俣の湯</option>
                    <option value="下田温泉">下田温泉</option>
                    <option value="田の原温泉">田の原温泉</option>
                    <option value="玉名温泉">玉名温泉</option>
                    <option value="杖立温泉">杖立温泉</option>
                    <option value="人吉温泉">人吉温泉</option>
                    <option value="日奈久温泉">日奈久温泉</option>
                    <option value="満願寺温泉">満願寺温泉</option>
                    <option value="南阿蘇温泉郷">南阿蘇温泉郷</option>
                    <option value="山鹿・平山温泉">山鹿・平山温泉</option>
                    <option value="湯の児温泉">湯の児温泉</option>
                    <option value="湯の鶴温泉">湯の鶴温泉</option>
                    <option value="湯山温泉">湯山温泉</option>
                    <option value="わいた温泉郷">わいた温泉郷</option>
                    <option value="仙酔峡温泉">仙酔峡温泉</option>
                    <option value="天草パール温泉">天草パール温泉</option>
                    <option value="山鹿温泉">山鹿温泉</option>
                  </optgroup>

                  <optgroup label="大分">
                    <option value="赤川温泉">赤川温泉</option>
                    <option value="天ヶ瀬温泉">天ヶ瀬温泉</option>
                    <option value="臼杵温泉">臼杵温泉</option>
                    <option value="久住温泉">久住温泉</option>
                    <option value="九重“夢”温泉郷">九重“夢”温泉郷</option>
                    <option value="瀬の本温泉">瀬の本温泉</option>
                    <option value="宇佐湯温泉">宇佐湯温泉</option>
                    <option value="日田温泉">日田温泉</option>
                    <option value="富貴寺温泉">富貴寺温泉</option>
                    <option value="別府温泉郷">別府温泉郷</option>
                    <option value="湯平温泉">湯平温泉</option>
                    <option value="由布院温泉">由布院温泉</option>
                    <option value="由布院温泉">由布院温泉</option>
                    <option value="別府温泉">別府温泉</option>
                    <option value="夷谷温泉">夷谷温泉</option>
                  </optgroup>

                  <optgroup label="宮崎">
                    <option value="青島温泉">青島温泉</option>
                    <option value="木城温泉">木城温泉</option>
                    <option value="北郷温泉">北郷温泉</option>
                    <option value="京町温泉">京町温泉</option>
                    <option value="曽山寺温泉">曽山寺温泉</option>
                    <option value="常盤温泉">常盤温泉</option>
                    <option value="宮崎リゾート温泉">宮崎リゾート温泉</option>
                  </optgroup>

                  <optgroup label="鹿児島">
                    <option value="市来温泉">市来温泉</option>
                    <option value="指宿温泉">指宿温泉</option>
                    <option value="鹿児島温泉">鹿児島温泉</option>
                    <option value="かごっま温泉">かごっま温泉</option>
                    <option value="冠岳温泉">冠岳温泉</option>
                    <option value="霧島温泉（霧島温泉郷、霧島神宮温泉郷、妙見・安楽温泉郷、日当山温泉郷）">
                      霧島温泉（霧島温泉郷、霧島神宮温泉郷、妙見・安楽温泉郷、日当山温泉郷）
                    </option>
                    <option value="くしき野白浜温泉">くしき野白浜温泉</option>
                    <option value="羽島温泉">羽島温泉</option>
                    <option value="東温泉">東温泉</option>
                    <option value="宮之城温泉">宮之城温泉</option>
                    <option value="湯之尾温泉">湯之尾温泉</option>
                    <option value="つるまる温泉">つるまる温泉</option>
                  </optgroup>

                  <optgroup label="沖縄">
                    <option value="沖縄ちゃたん温泉">沖縄ちゃたん温泉</option>
                    <option value="沖縄逸の彩温泉">沖縄逸の彩温泉</option>
                    <option value="さしきの猿人の湯">さしきの猿人の湯</option>
                    <option value="南城ゆいんち温泉　琉球やはらの湯">
                      南城ゆいんち温泉　琉球やはらの湯
                    </option>
                  </optgroup>
                </Select>
              </FormControl>
            </p>
            <p>
              住所
              <input
                type={"text"}
                style={{ width: "30%" }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </p>
            <p>
              <div>アクセス</div>
              <textarea
                style={{ width: "80%", height: "10vh" }}
                value={access}
                type={"text"}
                onChange={(e) => setAccess(e.target.value)}
              />
            </p>
            <p>
              電話番号(ハイフンあり)
              <input
                value={tell}
                type={"text"}
                onChange={(e) => setTell(e.target.value)}
              />
            </p>
            <p>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "35%" }}
                size="small"
              >
                <InputLabel>基本部門</InputLabel>
                <Select
                  label="基本部門"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {list.map((cat, i) => (
                    <MenuItem key={cat} value={category_list[i]}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <div>こだわり部門1</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "35%" }}
                size="small"
              >
                <InputLabel>こだわり部門1</InputLabel>
                <Select
                  label="こだわり部門1"
                  value={stick1}
                  onChange={(e) => setStick1(e.target.value)}
                >
                  {stick_name.map((cat, i) => (
                    <MenuItem key={cat} value={stick_list[i]}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <div>こだわり部門2</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "35%" }}
                size="small"
              >
                <InputLabel>こだわり部門2</InputLabel>
                <Select
                  label="こだわり部門2"
                  value={stick2}
                  onChange={(e) => setStick2(e.target.value)}
                >
                  {stick_name.map((cat, i) => (
                    <MenuItem key={cat} value={stick_list[i]}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <div>こだわり部門3</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "35%" }}
                size="small"
              >
                <InputLabel>こだわり部門3</InputLabel>
                <Select
                  label="こだわり部門3"
                  value={stick3}
                  onChange={(e) => setStick3(e.target.value)}
                >
                  {stick_name.map((cat, i) => (
                    <MenuItem key={cat} value={stick_list[i]}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <div>こだわり部門4</div>
              <FormControl
                sx={{ m: 1, minWidth: 80, width: "35%" }}
                size="small"
              >
                <InputLabel>こだわり部門4</InputLabel>
                <Select
                  label="こだわり部門4"
                  value={stick4}
                  onChange={(e) => setStick4(e.target.value)}
                >
                  {stick_name.map((cat, i) => (
                    <MenuItem key={cat} value={stick_list[i]}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <div>説明文(Shift+EnterまたはAlt+Enterで改行)</div>
            <p>
              <div style={{ width: "50%", border: "1px solid #000" }}>
                <Editor
                  onChange={onChangeEdit}
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  wrapperClassName={wrapper}
                  editorClassName={editor}
                  toolbarClassName={toolbar}
                />
              </div>
            </p>
            <p>
              <div>関連情報(ない場合は空白)</div>
              <p>
                <input
                  type={"text"}
                  value={label1}
                  placeholder={"関連情報ラベル1"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setLabel1(e.target.value)}
                />
                <input
                  type={"text"}
                  value={info1}
                  placeholder={"関連情報URL1"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setInfo1(e.target.value)}
                />
              </p>
              <p>
                <input
                  type={"text"}
                  value={label2}
                  placeholder={"関連情報ラベル2"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setLabel2(e.target.value)}
                />
                <input
                  type={"text"}
                  value={info2}
                  placeholder={"関連情報URL2"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setInfo2(e.target.value)}
                />
              </p>
              <p>
                <input
                  type={"text"}
                  value={label3}
                  placeholder={"関連情報ラベル3"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setLabel3(e.target.value)}
                />
                <input
                  type={"text"}
                  value={info3}
                  placeholder={"関連情報URL3"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setInfo3(e.target.value)}
                />
              </p>
              <p>
                <input
                  type={"text"}
                  value={label4}
                  placeholder={"関連情報ラベル4"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setLabel4(e.target.value)}
                />
                <input
                  type={"text"}
                  value={info4}
                  placeholder={"関連情報URL4"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setInfo4(e.target.value)}
                />
              </p>
              <p>
                <input
                  type={"text"}
                  value={label5}
                  placeholder={"関連情報ラベル5"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setLabel5(e.target.value)}
                />
                <input
                  type={"text"}
                  value={info5}
                  placeholder={"関連情報URL5"}
                  style={{ width: "27%", display: "block" }}
                  onChange={(e) => setInfo5(e.target.value)}
                />
              </p>
            </p>
            <p>
              Instagram(ない場合は空白)
              <input
                type={"text"}
                style={{ width: "27%" }}
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </p>
            <p>
              Twitter(ない場合は空白)
              <input
                type={"text"}
                style={{ width: "27%" }}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </p>
            <p>
              FaceBook(ない場合は空白)
              <input
                type={"text"}
                style={{ width: "27%" }}
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </p>
            <p>
              YouTube(ない場合は空白)
              <input
                type={"text"}
                style={{ width: "27%" }}
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
              />
            </p>
            <p>
              GoogleMyBusiness(ない場合は空白)
              <input
                type={"text"}
                style={{ width: "27%" }}
                value={google}
                onChange={(e) => setGoogle(e.target.value)}
              />
            </p>
            <p>
              tiktok(ない場合は空白)
              <input
                type={"text"}
                style={{ width: "27%" }}
                value={tiktok}
                onChange={(e) => setTiktok(e.target.value)}
              />
            </p>
            {img ? (
              <p>
                <button
                  onClick={() => {
                    setImg(false);
                    setThumbnail("");
                  }}
                >
                  更新キャンセル
                </button>
                <p>
                  メイン画像
                  <input
                    type={"file"}
                    onChange={(e) => setMain(e.target.files)}
                  />
                </p>
                <div>
                  画像(コントロール押しながら9枚まで選択可)
                  <input
                    type={"file"}
                    multiple
                    onChange={(e) => setThumbnail(e.target.files)}
                  />
                </div>
              </p>
            ) : (
              <p>
                <button onClick={() => setImg(true)}>画像の更新をする</button>
              </p>
            )}
            <p>
              <div>バッジ選択</div>
              <p>
                <div>
                  バッジ１
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>ランク</InputLabel>
                    <Select
                      label="ランク"
                      value={batch1}
                      onChange={(e) => setBatch1(e.target.value)}
                    >
                      {rank_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>部門</InputLabel>
                    <Select
                      label="部門"
                      value={batch_name1}
                      onChange={(e) => setBatchName1(e.target.value)}
                    >
                      {all_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </p>
              <p>
                <div>
                  バッジ２
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>ランク</InputLabel>
                    <Select
                      label="ランク"
                      value={batch2}
                      onChange={(e) => setBatch2(e.target.value)}
                    >
                      {rank_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>部門</InputLabel>
                    <Select
                      label="部門"
                      value={batch_name2}
                      onChange={(e) => setBatchName2(e.target.value)}
                    >
                      {all_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </p>
              <p>
                <div>
                  バッジ３
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>ランク</InputLabel>
                    <Select
                      label="ランク"
                      value={batch3}
                      onChange={(e) => setBatch3(e.target.value)}
                    >
                      {rank_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>部門</InputLabel>
                    <Select
                      label="部門"
                      value={batch_name3}
                      onChange={(e) => setBatchName3(e.target.value)}
                    >
                      {all_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </p>
              <p>
                <div>
                  バッジ４
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>ランク</InputLabel>
                    <Select
                      label="ランク"
                      value={batch4}
                      onChange={(e) => setBatch4(e.target.value)}
                    >
                      {rank_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, minWidth: 80, width: "35%" }}
                    size="small"
                  >
                    <InputLabel>部門</InputLabel>
                    <Select
                      label="部門"
                      value={batch_name4}
                      onChange={(e) => setBatchName4(e.target.value)}
                    >
                      {all_name.map((cat, i) => (
                        <MenuItem key={cat} value={i}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </p>
            </p>
            {checked.length != 0 && (
              <div>
                <Button onClick={(e) => onClickUpdate()}>更新</Button>
              </div>
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              overflow: "auto",
              maxHeight: "100vh",
              bgcolor: "background.paper",
            }}
          >
            {data != "" &&
              data.map((value, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked === value}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": i }}
                      />
                    </ListItemIcon>
                    <ListItemText id={i} primary={value.name} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid xs={1}>
          <p>
            宿・ホテル検索
            <input
              type={"text"}
              value={search}
              placeholder="ホテル名"
              onChange={(e) => onSearch(e.target.value)}
            />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditHotel;
