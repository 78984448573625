import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

const Signin = () => {
  const [pass, setPass] = useState("");
  const passRef = useRef("");
  const onClick = () => {
    if (pass == "jecon#03") passRef.current.click();
  };
  return (
    <Box>
      <input
        style={{ display: "block" }}
        onChange={(e) => setPass(e.target.value)}
      />
      <Button onClick={(e)=>onClick()}>決定</Button>
      <Link hidden to="/Home" ref={passRef} />
    </Box>
  );
};

export default Signin;
