import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <p>
        <Link to="/FunOnsen">温泉総選挙</Link>
      </p>
      <p>
        <Link to="/FunHotel">宿・ホテル総選挙</Link>
      </p>
      <p>
        <Link to="/FunNews">新着情報</Link>
      </p>
    </div>
  );
};

export default Home;
