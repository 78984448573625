import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiHost } from "./info";
import axios from "axios";

const FunOnsen = () => {
  const [cnt, setCnt] = useState("");
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setCnt("");
    await axios.get(ApiHost + "/count").then((res) => {
      if (res.data != "") {
        setCnt(res.data);
      }
    });
  };
  return (
    <div>
      <h3>
        <b>登録数：</b>
        {cnt}
      </h3>
      <p>
        <Link to="/CreateSpring">温泉登録</Link>
      </p>
      <p>
        <Link to="/EditSpring">温泉編集</Link>
      </p>
      <p>
        <Link to="/DeleteSpring">温泉削除</Link>
      </p>
      <p>
        <Link to="/SetFavorite">話題の温泉設定</Link>
      </p>
      <p>
        <Link to="/Vote">投票数確認</Link>
      </p>
      <p>
        <Link to="/VoteLog">投票ログ確認</Link>
      </p>
      {/* <p>
        <Link to="/EditVoteSpring">投票数調整</Link>
      </p> */}
      <p>
        <Link to="/Email">メールアドレス確認</Link>
      </p>
    </div>
  );
};

export default FunOnsen;
