// export const ApiHost = "http://localhost:8000/api";
export const ApiHost = "https://api.onsen-ouen.jp/onsen/api";

// export const NewsHost = "http://localhost:8000/api";
export const NewsHost = "https://api.onsen-ouen.jp/news/api";

// export const HotelHost = "http://localhost:8000/api";
export const HotelHost = "https://onsen-ouen.jp/api.onsen-ouen.jp/hotel/api";
export const spas = [
  "その他",
  "所属温泉なし",
  "阿寒湖温泉",
  "旭岳温泉",
  "朝里川温泉",
  "芦別温泉",
  "網走湖畔温泉郷",
  "岩尾別温泉",
  "ウトロ温泉",
  "エルム高原温泉ゆったり",
  "雄阿寒温泉",
  "帯広温泉郷",
  "オムイ温泉",
  "温根湯温泉",
  "神威脇温泉保養所",
  "カルルス温泉",
  "川湯温泉",
  "北村温泉",
  "北湯沢温泉",
  "木野温泉",
  "霧多布温泉",
  "剣淵温泉",
  "支笏湖温泉",
  "岬の湯しゃこたん",
  "定山渓温泉",
  "知内温泉旅館ユートピア和楽園",
  "ススキノ温泉",
  "層雲峡温泉",
  "天塩川温泉",
  "天人峡温泉",
  "洞爺湖温泉",
  "十勝川温泉",
  "十勝岳温泉",
  "常呂川温泉",
  "苫小牧温泉",
  "とままえ温泉",
  "豊浦温泉",
  "ニセコ温泉郷",
  "ぬかびら源泉郷",
  "登別温泉",
  "函館温泉",
  "函館大沼温泉",
  "函館南かやべ大船温泉",
  "びえい白金温泉",
  "平磯温泉",
  "吹上温泉",
  "妹背牛温泉ペペル",
  "湯の川温泉",
  "養老牛温泉",
  "稚内中央温泉",
  "遠別町旭温泉",
  "中標津温泉",
  "浅虫温泉",
  "奥入瀬渓流温泉",
  "大鰐温泉",
  "追子野木久米源泉",
  "古牧温泉",
  "下風呂温泉",
  "田代温泉",
  "朔日町源泉",
  "薬研温泉",
  "八甲田山九湯会",
  "十和田湖畔温泉",
  "一関温泉郷",
  "奥州平泉温泉",
  "鶯宿温泉",
  "大船渡温泉",
  "金ヶ崎温泉郷",
  "夏油高原温泉郷",
  "瀬美温泉",
  "つなぎ温泉",
  "とぴや温泉",
  "永岡温泉",
  "八幡平市温泉郷",
  "花巻温泉郷",
  "平泉温泉",
  "みちのく城址温泉",
  "湯田温泉峡",
  "鉛温泉 藤三旅館",
  "花巻温泉",
  "台温泉",
  "金矢温泉",
  "松倉温泉",
  "渡り温泉",
  "山の神温泉",
  "新鉛温泉",
  "青根温泉",
  "秋保温泉",
  "小原温泉郷",
  "鎌先温泉",
  "作並温泉",
  "遠刈田温泉",
  "鳴子温泉郷",
  "太古天泉・松島温泉",
  "やくらい高原温泉",
  "鳴子温泉",
  "東鳴子温泉",
  "川渡温泉",
  "中山平温泉",
  "鬼首温泉",
  "秋田八幡平温泉郷",
  "秋の宮温泉",
  "大湯温泉",
  "男鹿温泉郷",
  "小安峡温泉",
  "仙北温泉",
  "たしろ温泉",
  "田代温泉",
  "中里温泉",
  "南郷温泉",
  "乳頭温泉郷",
  "湯瀬温泉",
  "玉川温泉",
  "赤倉温泉",
  "赤湯温泉",
  "あつみ温泉",
  "大石田温泉",
  "奥おおえ柳川温泉",
  "小野川温泉",
  "月山志津温泉",
  "かみのやま温泉",
  "銀山温泉",
  "黒鴨温泉滝の湯",
  "蔵王温泉",
  "寒河江温泉",
  "さくらんぼ東根温泉",
  "白布温泉",
  "瀬見温泉",
  "鷹野湯温泉",
  "天童温泉",
  "なの花温泉",
  "白鷹温泉",
  "肘折温泉",
  "ひまわり温泉ゆらら",
  "舟唄温泉",
  "湯田川温泉",
  "湯野浜温泉",
  "由良温泉",
  "会津芦ノ牧温泉",
  "会津東山温泉",
  "会津湯野上温泉",
  "熱塩温泉",
  "穴原温泉",
  "飯坂温泉",
  "猪苗代温泉",
  "いわき藤間温泉",
  "いわき湯本温泉",
  "岩瀬湯本温泉",
  "表磐梯温泉",
  "甲子温泉",
  "新甲子温泉",
  "高湯温泉",
  "岳温泉",
  "土湯温泉",
  "天栄温泉",
  "那須白河温泉",
  "沼尻温泉",
  "猫啼温泉",
  "羽鳥湖温泉",
  "磐梯熱海温泉",
  "磐梯山清水平温泉",
  "磐梯横向温泉",
  "二岐温泉",
  "宮下温泉",
  "深沢温泉",
  "あぶくま温泉",
  "磯原温泉",
  "五浦温泉",
  "大洗温泉",
  "奥久慈温泉郷",
  "筑波温泉",
  "手綱温泉",
  "屛風ヶ浦温泉",
  "平潟港温泉",
  "板室温泉",
  "大田原温泉",
  "川治温泉",
  "川俣温泉",
  "喜連川温泉",
  "⿁怒川温泉",
  "黒羽温泉五峰の湯",
  "塩原温泉",
  "中禅寺温泉",
  "那須温泉",
  "日光温泉",
  "日光湯元温泉",
  "湯津上温泉やすらぎの湯",
  "湯西川温泉",
  "赤城温泉",
  "伊香保温泉",
  "磯部温泉",
  "猪田温泉絹の湯",
  "上野村温泉郷",
  "老神温泉",
  "奥軽井沢温泉",
  "鹿沢温泉",
  "片品温泉郷",
  "上牧温泉",
  "川原湯温泉",
  "桐生新里温泉",
  "草津温泉",
  "四万温泉",
  "尻焼温泉",
  "嬬恋バラギ温泉",
  "藤岡温泉",
  "万座温泉",
  "みなかみ18湯",
  "本白根温泉",
  "薬師温泉",
  "川場温泉",
  "浜平温泉・しおじの湯",
  "小鹿野温泉",
  "金山温泉",
  "柴原温泉",
  "秩父大滝温泉三峰神の湯秩父七湯鳩の湯温泉",
  "秩父吉田温泉・星音の湯",
  "和銅鉱泉",
  "旭九十九里温泉",
  "一宮温泉",
  "犬吠埼温泉",
  "岩井湯元温泉",
  "亀山温泉",
  "鴨川温泉",
  "小湊温泉",
  "白子温泉",
  "館山温泉郷",
  "千倉温泉郷",
  "⾧生温泉",
  "南房総白浜温泉郷",
  "安房温泉",
  "養老渓谷温泉",
  "ラジャ舞浜温泉",
  "秋川渓谷・瀬音の湯",
  "浅草観音温泉",
  "数馬の湯",
  "亀島川温泉",
  "東京江東温泉",
  "八日市場温泉あ・うんの湯",
  "川崎東田町温泉",
  "中川温泉",
  "東丹沢七沢温泉郷",
  "箱根温泉",
  "三浦マホロバ温泉",
  "湯河原温泉",
  "飯山温泉郷",
  "南足柄温泉",
  "赤倉温泉",
  "池の平温泉",
  "糸魚川温泉",
  "岩室温泉",
  "鵜の浜温泉",
  "越後湯沢温泉",
  "大湯温泉",
  "貝掛温泉",
  "麒麟山温泉",
  "結東温泉",
  "五頭温泉郷",
  "咲花温泉",
  "佐渡加茂湖温泉",
  "新赤倉温泉",
  "新胎内温泉",
  "杉野沢温泉",
  "関温泉",
  "瀬波温泉",
  "鷹ノ巣温泉",
  "月岡温泉",
  "燕温泉",
  "栃尾又温泉",
  "舞子温泉",
  "柵口温泉",
  "松之山温泉",
  "丸山温泉",
  "妙高温泉",
  "六日町温泉",
  "安田温泉",
  "弥彦温泉",
  "湯田上温泉",
  "大崎温泉",
  "じょんのび村温泉",
  "生地温泉",
  "宇奈月温泉",
  "越中つるぎ温泉",
  "黒部峡谷温泉群",
  "庄川温泉郷",
  "富山温泉",
  "能登半島国定公園氷見温泉郷",
  "宮島温泉",
  "粟津温泉",
  "岩間温泉",
  "片山津温泉",
  "金沢無量寺温泉",
  "小松温泉",
  "白峰温泉",
  "珠洲温泉",
  "辰口温泉",
  "千里浜温泉郷",
  "白山一里野温泉",
  "松任CCZ温泉",
  "山代温泉",
  "山中温泉",
  "湯涌温泉",
  "和倉温泉",
  "輪島温泉郷",
  "能登黒川温泉",
  "あわら温泉",
  "越前くりや温泉",
  "越前水仙の里温泉",
  "勝山温泉",
  "三国温泉",
  "みかた温泉",
  "越前玉川温泉",
  "越前南部温泉",
  "花みずき温泉",
  "越前糸生温泉",
  "赤石温泉",
  "欽明温泉",
  "石和温泉",
  "春日居温泉",
  "下部温泉",
  "積翠寺温泉",
  "富士河口湖温泉郷",
  "真木温泉",
  "万葉温泉",
  "みはらしの丘みたまの湯",
  "桃の木温泉",
  "山中湖温泉",
  "湯村温泉",
  "竜王ラドン温泉",
  "増富温泉",
  "秋山郷",
  "浅間温泉",
  "飯田城温泉",
  "美ヶ原温泉",
  "大町温泉郷",
  "奥蓼科温泉",
  "小布施温泉",
  "鹿教湯温泉",
  "釜沼温泉",
  "上高地温泉",
  "上諏訪温泉",
  "軽井沢の温泉",
  "久米川温泉",
  "さわんど温泉",
  "志賀高原の温泉",
  "下諏訪温泉",
  "白樺湖温泉",
  "白骨温泉",
  "蓼科温泉",
  "戸狩温泉",
  "戸倉上山田温泉",
  "扉温泉",
  "中尾山温泉",
  "南木曽温泉郷",
  "野沢温泉",
  "乗鞍高原温泉",
  "白馬姫川温泉",
  "昼神温泉",
  "別所温泉",
  "穂高温泉郷",
  "馬曲温泉",
  "湯田中渋温泉郷",
  "池田さくら温泉",
  "恵那峡温泉",
  "奥飛騨温泉郷",
  "⿁岩温泉",
  "郡上温泉",
  "下呂温泉",
  "白川郷",
  "白川郷平瀬温泉",
  "渡合温泉",
  "⾧良川温泉",
  "濁河温泉",
  "飛騨高山温泉",
  "平湯温泉",
  "湯屋温泉",
  "彦根千乃松原温泉",
  "網代温泉",
  "熱川温泉",
  "熱海温泉",
  "天城湯ヶ島温泉郷",
  "伊豆高原温泉",
  "伊豆⾧岡温泉",
  "伊東温泉",
  "稲取温泉",
  "今井浜温泉",
  "梅ヶ島温泉",
  "大川温泉",
  "掛川温泉",
  "片瀬・白田温泉",
  "河津温泉",
  "川根温泉",
  "舘山寺温泉",
  "木太刀の湯",
  "雲見温泉",
  "静岡まちなか温泉",
  "下賀茂温泉",
  "下田温泉",
  "修善寺温泉",
  "寸又峡温泉",
  "土肥温泉",
  "堂ヶ島温泉",
  "畑毛温泉",
  "松崎温泉",
  "三島温泉",
  "三ヶ日温泉",
  "焼津温泉",
  "雄踏温泉",
  "湯ヶ島高原温泉",
  "弓ヶ浜温泉",
  "油山温泉",
  "犬山温泉",
  "尾張温泉郷",
  "蒲郡温泉郷",
  "吉良温泉",
  "笹戸温泉",
  "猿投温泉",
  "豊川市本宮温泉",
  "西浦温泉",
  "南知多温泉郷",
  "湯谷温泉",
  "伊勢二見の温泉",
  "榊原温泉",
  "志摩の温泉",
  "玉城弘法温泉",
  "鳥羽温泉郷",
  "トマト温泉",
  "中伊勢温泉郷",
  "⾧島温泉",
  "浜島温泉",
  "湯の山温泉",
  "奥伊勢宮川温泉",
  "おごと温泉",
  "浅茂川浦島温泉",
  "天橋立温泉",
  "嵐山温泉",
  "大原温泉",
  "木津温泉",
  "久美の浜温泉郷",
  "丹後温泉",
  "鳴き砂温泉",
  "能勢アートレイク温泉",
  "ひよし温泉",
  "宮津温泉",
  "夕日ヶ浦温泉",
  "湯の花温泉",
  "るり渓温泉",
  "天見温泉",
  "生駒西村温泉",
  "犬鳴山温泉",
  "牛滝温泉",
  "奥水間温泉",
  "能勢温泉",
  "伏尾温泉",
  "松葉温泉滝の湯",
  "箕面温泉",
  "赤穂温泉",
  "有馬温泉",
  "淡路島の温泉",
  "香住温泉郷",
  "城崎温泉",
  "こうでら温泉",
  "神戸二軒茶屋温泉",
  "神戸みなと温泉",
  "塩田温泉",
  "太山寺温泉",
  "宝塚・武田尾温泉",
  "竹野温泉",
  "ハチ北温泉",
  "浜坂温泉郷",
  "円山川温泉",
  "湯村温泉",
  "大宇陀温泉あきののゆ",
  "大峯山洞川温泉",
  "天然温泉 朱雀の湯",
  "十津川温泉郷（十津川温泉・湯泉地温泉・上湯温泉）",
  "奈良宇陀温泉郷",
  "平城宮温泉",
  "大和平群温泉",
  "吉野山温泉",
  "みつえ温泉 姫石の湯",
  "紀州有田川温泉",
  "かなや明恵峡温泉",
  "熊野本宮温泉郷",
  "紀伊見温泉",
  "紀州温泉",
  "白浜温泉",
  "天神温泉",
  "南紀勝浦温泉",
  "花山温泉",
  "やどり温泉いやしの湯",
  "湯の峰温泉",
  "龍神温泉",
  "龍門山温泉",
  "渡瀬温泉",
  "岩井温泉",
  "皆生温泉",
  "鹿野温泉",
  "関金温泉",
  "東郷温泉",
  "鳥取温泉",
  "浜村温泉",
  "はわい温泉",
  "三朝温泉",
  "吉岡温泉",
  "旭温泉",
  "有福温泉",
  "風の国温泉",
  "さぎの湯温泉",
  "三瓶山温泉郷 飯南エリア",
  "三瓶温泉",
  "大社温泉",
  "玉造温泉",
  "斐乃上温泉",
  "松江しんじ湖温泉",
  "美又温泉",
  "出雲湯の川温泉",
  "温泉津温泉",
  "湯屋温泉",
  "奥津温泉",
  "瀬戸内温泉たまの湯",
  "湯郷温泉",
  "湯原温泉郷",
  "宮島・宮浜の温泉",
  "きのえ温泉",
  "君田温泉",
  "庄原さくら温泉",
  "鞆の浦温泉",
  "湯来温泉",
  "龍頭温泉",
  "宮浜温泉",
  "宮島温泉",
  "湯の山温泉",
  "笠戸島大城温泉",
  "片添ヶ浜温泉",
  "川棚温泉",
  "菊川温泉",
  "俵山温泉",
  "⾧門湯本温泉",
  "萩温泉郷",
  "光室積温泉",
  "湯田温泉",
  "油谷湾温泉",
  "大歩危・祖谷温泉郷",
  "四季美谷温泉",
  "もみじ川温泉",
  "わじき温泉",
  "絹島温泉ベッセルおおちの湯",
  "こんぴら温泉郷",
  "塩江温泉",
  "小豆島の温泉",
  "高松クレーターの湯天然温泉きらら",
  "ニューレオマ天然温泉",
  "母神温泉",
  "薬師湯温泉",
  "奥道後温泉",
  "鹿野川温泉",
  "道後温泉",
  "鈍川温泉",
  "湯ノ浦温泉",
  "東温市ふるさと交流館さくらの湯",
  "あしずり温泉郷",
  "郷麓温泉",
  "木の香温泉",
  "⾧岡温泉",
  "ながおか温泉",
  "仁淀川伊野温泉",
  "赤村ふるさとセンター源じいの森温泉",
  "筑後川温泉",
  "原鶴温泉",
  "二日市温泉",
  "船小屋温泉郷",
  "奥おおえ 柳川温泉",
  "若松ひびき温泉",
  "脇田温泉",
  "嬉野温泉",
  "唐津温泉",
  "北方温泉",
  "武雄温泉",
  "たら竹崎温泉",
  "古湯･熊の川温泉",
  "壱岐湯ノ本温泉",
  "稲佐山温泉",
  "雲仙温泉",
  "小浜温泉",
  "島原温泉",
  "そのぎ茶温泉",
  "⾧崎ゆりの温泉",
  "野母崎温泉",
  "平戸温泉",
  "平戸たびら温泉",
  "阿蘇内牧温泉",
  "植木温泉",
  "小田温泉",
  "上天草温泉郷",
  "菊池温泉",
  "黒川温泉",
  "佐俣の湯",
  "下田温泉",
  "田の原温泉",
  "玉名温泉",
  "杖立温泉",
  "人吉温泉",
  "日奈久温泉",
  "満願寺温泉",
  "南阿蘇温泉郷",
  "山鹿・平山温泉",
  "湯の児温泉",
  "湯の鶴温泉",
  "湯山温泉",
  "わいた温泉郷",
  "赤川温泉",
  "天ヶ瀬温泉",
  "臼杵温泉",
  "久住温泉",
  "九重“夢”温泉郷",
  "瀬の本温泉",
  "宇佐湯温泉",
  "日田温泉",
  "富貴寺温泉",
  "別府温泉郷",
  "湯平温泉",
  "由布院温泉",
  "青島温泉",
  "木城温泉",
  "北郷温泉",
  "京町温泉",
  "曽山寺温泉",
  "常盤温泉",
  "宮崎リゾート温泉",
  "市来温泉",
  "指宿温泉",
  "鹿児島温泉",
  "かごっま温泉",
  "冠岳温泉",
  "霧島温泉（霧島温泉郷、霧島神宮温泉郷、妙見・安楽温泉郷、日当山温泉郷）",
  "くしき野白浜温泉",
  "羽島温泉",
  "東温泉",
  "宮之城温泉",
  "湯之尾温泉",
  "つるまる温泉",
  "沖縄ちゃたん温泉",
  "沖縄逸の彩温泉",
  "さしきの猿人の湯",
  //追加分
  "みはま温泉",
  "馬門温泉",
  "岩木山温泉郷（嶽温泉、百沢温泉など）",
  "水沢温泉郷",
  "砂丘温泉ゆめろん",
  "森岳温泉郷",
  "潮来水原温泉",
  "しゃくなげの湯っこ　五葉温泉",
  "大洲市交流促進センター鹿野川荘",
  "見奈良温泉",
  "別府温泉",
  "夷谷温泉",
  "富田林嶽山温泉２号泉",
  "グリーンミュージアム神郷温泉",
  "新見千屋温泉　いぶきの里",
  "南城ゆいんち温泉　琉球やはらの湯",
  "讃岐丸亀",
  "京極の湯",
  "箱根湯本温泉",
  "強羅温泉",
  "箱根小涌谷温泉",
  "箱根仙石原",
  "仙酔峡温泉",
  "天草パール温泉",
  "山鹿温泉",
  "みなかみ18湯　谷川温泉",
  "中津渓谷　ゆの森",
  "きゅうらぎ温泉　佐用姫の湯",
  "彦根千乃松原温泉",
  "下田・相玉温泉",
  "宇佐美温泉",
  "熱海伊豆山温泉",
  "河津七滝温泉・大滝温泉",
  "御殿場温泉",
  "接岨峡温泉",
  "飯南町健康増進施設　加田の湯",
  "南房総温泉",
  "館山温泉郷",
  "羽田空港 泉天空温泉",
  "有明　泉天空温泉",
  "青梅石神温泉",
  "霧降高原",
  "馬頭温泉郷",
  "奥日光湯元温泉",
  "塩原温泉郷",
  "那須温泉郷",
  "法林寺温泉",
  "佐世保",
  "松代温泉",
  "白馬八方温泉",
  "美榛温泉",
  "洞川温泉地",
  "曽爾高原温泉　お亀の湯",
  "塩の道温泉ホテルホワイトクリフ",
  "赤穂御崎温泉",
  "愛宕山泉源",
  "小倉山温泉",
  "別所新加賀温泉",
  "芦原温泉",
  "会津若松",
  "柳川温泉",
  "王丸温泉　宗像王丸・天然温泉やまつばさ",
  "小樽",
  "ヤムワッカナイ温泉港のゆ",
  "びらとり温泉　ゆから",
  "知内温泉",
  "こもれび温泉",
  "しょさんべつ温泉",
  "いわない温泉",
  "鳥羽",
  "本浦温泉",
  "潮香の湯",
  "気仙沼温泉",
  "松島温泉",
  "やくらい薬師の湯",
  "陶芸の里ゆ～らんど",
  "鳴子温泉",
  "南紀串本",
  "和歌の浦温泉",
  "松之山温泉",
  "田尻湖高原温泉",
  "伊良湖温泉",
  "鬼押温泉",
  "大手町源泉",
  "南木曽温泉",
  "峰温泉",
];
