import { Grid } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import CreateHotel from "./CreateHotel";
import CreateNews from "./CreateNews";
import CreateSpring from "./CreateSpring";
import DeleteHotel from "./DeleteHotel";
import DeleteNews from "./DeleteNews";
import DeleteSpring from "./DeleteSpring";
import EditHotel from "./EditHotel";
import EditNews from "./EditNews";
import EditSpring from "./EditSpring";
import Email from "./Email";
import FunHotel from "./FunHotel";
import FunNews from "./FunNews";
import FunOnsen from "./FunOnsen";
import Home from "./Home";
import HotelEmail from "./HotelEmail";
import SetFavorite from "./SetFavorite";
import Signin from "./Signin";
import Vote from "./Vote";
import VoteHotel from "./VoteHotel";
import VoteLog from "./VoteLog";
import VoteLogHotel from "./VoteLogHotel";
import EditVoteSpring from "./EditVoteSpring";
import EditVoteHotel from "./EditVoteHotel";

function App() {
  return (
    <Grid container sx={{marginTop:"2vw",paddingBottom:"2vw"}}>
      <Grid item xs={0.5} />
      <Grid item xs={11.5}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/FunOnsen" element={<FunOnsen />} />
            <Route path="/FunNews" element={<FunNews />} />
            <Route path="/FunHotel" element={<FunHotel />} />
            <Route path="/CreateHotel" element={<CreateHotel />} />
            <Route path="/EditHotel" element={<EditHotel />} />
            <Route path="/DeleteHotel" element={<DeleteHotel />} />
            <Route path="/HotelEmail" element={<HotelEmail />} />
            <Route path="/EditNews" element={<EditNews />} />
            <Route path="/CreateNews" element={<CreateNews />} />
            <Route path="/DeleteNews" element={<DeleteNews />} />
            <Route path="/EditNews" element={<EditNews />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/CreateSpring" element={<CreateSpring />} />
            <Route path="/SetFavorite" element={<SetFavorite />} />
            <Route path="/DeleteSpring" element={<DeleteSpring />} />
            <Route path="/EditVoteSpring" element={<EditVoteSpring />} />
            <Route path="/EditVoteHotel" element={<EditVoteHotel />} />
            <Route path="/EditSpring" element={<EditSpring />} />
            <Route path="/Email" element={<Email />} />
            <Route path="/VoteHotel" element={<VoteHotel />} />
            <Route path="/Vote" element={<Vote />} />
            <Route path="/VoteLog" element={<VoteLog />} />
            <Route path="/VoteLogHotel" element={<VoteLogHotel />} />
          </Routes>
        </BrowserRouter>
      </Grid>
    </Grid>
  );
}

export default App;
