import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiHost } from "./info";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
const EditVoteSpring = () => {
  const [data, setData] = useState("");
  const [checked, setChecked] = useState("");
  const [vote, setVote] = useState("");
  const [num, setNum] = useState("");
  const [search, setSearch] = useState("");
const [request,setRequest] = useState("")
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setData("");
    await axios.get(ApiHost + "/category/all").then((res) => {
      if (res.data != "") {
        setData(res.data);
      }
    });
  };
  const onClickDelete = async () => {
    const nd = new Date();
    const mm = ("0" + (nd.getMonth() + 1)).slice(-2);
    const dd = ("0" + nd.getDate()).slice(-2);
    const now = `${nd.getFullYear()}-${mm}-${dd} ${nd.getHours()}:${nd.getMinutes()}:${nd.getSeconds()}`;

    try {
      await axios.post(ApiHost + "/controlVote", {
        id: checked.id,
        date: now,
        ip: "host",
        ua: "host",
        num: num,
      });
      setNum("");
      getVote(checked.id);
      alert("成功");
    } catch {
      alert("失敗");
    }
    getData();
  };

  const handleToggle = (value) => {
    setVote("");
    setChecked(value);
    getVote(value.id);
  };
  const getVote = async (id) => {
    axios.get(ApiHost + "/getVote/" + id).then((res) => {
      if (res.data != "") {
        setVote(res.data);
      } else {
        setVote(0);
      }
    });
  };
  const requestData = async () => {
    await axios.get(ApiHost + "/category/all").then((res) => {
      if (res.data != "") {
        setRequest(res.data);
      }
    });
  };
  const onSearch = (value) => {
    setSearch(value);
    requestData();
    const Entry = [];
    for (var d of request) if (d.name.includes(value)) Entry.push(d);
    setData(Entry);
    if (value == "") getData();
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          {checked != "" && (
            <p>
              <b>{checked.name}の投票数：</b>
              {vote}
            </p>
          )}
          <input
            style={{ width: "35%" }}
            value={num}
            onChange={(e) => setNum(e.target.value)}
            type="number"
            max="50"
            min="0"
            placeholder="追加する投票数を入力してください(最大50)"
          />
          <Button onClick={(e) => onClickDelete()}>追加</Button>
        </Grid>
        <Grid item xs={6}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              overflow: "auto",
              maxHeight: "100vh",
              bgcolor: "background.paper",
            }}
          >
            {data != "" &&
              data.map((value, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked === value}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": i }}
                      />
                    </ListItemIcon>
                    <ListItemText id={i} primary={value.name} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item xs={1}>
          <p>
            温泉地名
            <input
              type={"text"}
              value={search}
              placeholder="温泉地名"
              onChange={(e) => onSearch(e.target.value)}
            />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditVoteSpring;
