import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { ApiHost } from "./info";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const VoteLog = () => {
  const [data, setData] = useState("");
  const [checked, setChecked] = useState("");
  const [vote, setVote] = useState("");

  const [request, setRequest] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setData("");
    await axios.get(ApiHost + "/category/all").then((res) => {
      if (res.data != "") {
        setData(res.data);
      }
    });
  };

  const requestData = async () => {
    await axios.get(ApiHost + "/category/all").then((res) => {
      if (res.data != "") {
        return setRequest(res.data);
      }
    });
  };

  const handleToggle = async (value) => {
    setChecked(value);
    await axios.get(ApiHost + "/getVoteLog/" + value.id).then((res) => {
      if (res.data != "") setVote(res.data);
      else setVote("");
    });
  };

  const onSearch = (value) => {
    setSearch(value);
    requestData();
    const Entry = [];
    for (var d of request) if (d.name.includes(value)) Entry.push(d);
    setData(Entry);
    if (value == "") getData();
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <div style={{ height: "90vh", overflow: "scroll", width: "100%" }}>
            <p style={{ display: "flex" }}>
              <b style={{ width: "25%", display: "block" }}>日付</b>
              <b style={{ width: "15%", display: "block" }}>IPアドレス</b>
              <b style={{ width: "60%", display: "block" }}>
                ユーザーエージェント
              </b>
            </p>
            {vote != "" &&
              vote.map((data, i) => (
                <>
                  {data.date >= "2023-07-26 10:00:00"}
                  <div key={i} style={{ display: "flex" }}>
                    <div style={{ width: "25%", display: "block" }}>
                      {data.date}
                    </div>
                    <div style={{ width: "15%", display: "block" }}>
                      {data.ip}
                    </div>
                    <div style={{ width: "60%", display: "block" }}>
                      {data.ua}
                    </div>
                  </div>
                </>
              ))}
          </div>
        </Grid>
        <Grid item xs={4}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              overflow: "auto",
              maxHeight: "100vh",
              bgcolor: "background.paper",
            }}
          >
            {data != "" &&
              data.map((value, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked === value}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": i }}
                      />
                    </ListItemIcon>
                    <ListItemText id={i} primary={value.name} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid xs={1}>
          <p>
            温泉検索
            <input
              type={"text"}
              value={search}
              placeholder="温泉名"
              onChange={(e) => onSearch(e.target.value)}
            />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default VoteLog;
