import React, { useEffect, useState } from "react";
import axios from "axios";
import {  NewsHost } from "./info";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
const DeleteNews = () => {
  const [data, setData] = useState("");
  const [checked, setChecked] = useState([]);
  const [del, SetDel] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setData("");
    await axios.get(NewsHost + "/news/all").then((res) => {
      if (res.data != "") {
        setData(res.data);
      }
    });
  };

  const onClickDelete = async () => {
    try {
      for (var i = 0; i < checked.length; i++) {
        await axios.delete(NewsHost + "/news/" + checked[i].id);
      }
      setChecked([]);
      alert("成功");
    } catch {
      alert("失敗");
    }
    getData();
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      if (newChecked.length < 12) newChecked.push(value);
      else alert("上限エラー");
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Button onClick={(e) => onClickDelete()}>削除</Button>
        </Grid>
        <Grid item xs={6}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              overflow: "auto",
              maxHeight: "100vh",
              bgcolor: "background.paper",
            }}
          >
            {data != "" &&
              data.map((value, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": i }}
                      />
                    </ListItemIcon>
                    <ListItemText id={i} primary={value.title} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeleteNews;
