import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML, convertFromHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { NewsHost } from "./info";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
const EditNews = () => {
  const [data, setData] = useState("");
  const [checked, setChecked] = useState();
  const [del, SetDel] = useState("");

  const [title, setTitle] = useState("");
  const [from, setFrom] = useState("");
  const [date, setDate] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [html, setHtml] = useState(null);

  const wrapper = {
    padding: "1rem",
    border: "1px solid #ccc",
  };
  const editor = {
    backgroundColor: "lightgray",
    padding: "1rem",
    border: "1px solid #ccc",
  };
  const toolbar = {
    border: "1px solid #ccc",
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeEdit = () => {
    setHtml(convertToHTML(editorState.getCurrentContent()));
  };

  const onClickCreate = async () => {
    const file = new FormData();
    file.append("date", date);
    file.append("html", html);
    file.append("title", title);
    file.append("from", from);
    await axios
      .post(NewsHost + "/news/" + checked.id, file, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setChecked("");
        setFrom("");
        setTitle("");
        setHtml("");
        setDate("");
        setEditorState(  EditorState.createEmpty())
        alert("成功");
        getData()
      })
      .catch((e) => {
        alert("失敗");
      });
  };

  const getData = async () => {
    setData("");
    await axios.get(NewsHost + "/news/all").then((res) => {
      if (res.data != "") {
        setData(res.data);
      }
    });
  };

  const handleToggle = (value) => {
    setChecked(value);
    setFrom(value.from);
    setTitle(value.title);
    setDate(value.date);
    setEditorState(() =>
      EditorState.createWithContent(convertFromHTML(value.html))
    );
  };
  
  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <p>
            <div>タイトル</div>
            <input
              value={title}
              type={"text"}
              style={{ width: "80%" }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </p>
          <p>
            <div>差出人</div>
            <input
              value={from}
              type={"text"}
              style={{ width: "80%" }}
              onChange={(e) => setFrom(e.target.value)}
            />
          </p>
          <p>
            <div>本文(Shift+EnterまたはAlt+Enterで改行)</div>
            <div style={{ width: "80%", border: "1px solid #000" }}>
              <Editor
                onChange={onChangeEdit}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName={wrapper}
                editorClassName={editor}
                toolbarClassName={toolbar}
              />
            </div>
          </p>
          <Button onClick={(e) => onClickCreate()}>更新</Button>
        </Grid>
        <Grid item xs={4}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              overflow: "auto",
              maxHeight: "100vh",
              bgcolor: "background.paper",
            }}
          >
            {data != "" &&
              data.map((value, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={(e) => handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked == value}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": i }}
                      />
                    </ListItemIcon>
                    <ListItemText id={i} primary={value.title} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditNews;
