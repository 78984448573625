import React from "react";
import { Link } from "react-router-dom";

const FunNews = () => {
  return (
    <div>
      <p>
        <Link to="/CreateNews">新着情報作成</Link>
      </p>
      <p>
        <Link to="/DeleteNews">新着情報削除</Link>
      </p>  
      <p>
        <Link to="/EditNews">新着情報編集</Link>
      </p>
    </div>
  );
};

export default FunNews;
