import React, { useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { NewsHost } from "./info";
import { Button } from "@mui/material";
const CreateNews = () => {
  const [title, setTitle] = useState("");
  const [from, setFrom] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [html, setHtml] = useState(null);

  const wrapper = {
    padding: "1rem",
    border: "1px solid #ccc",
  };
  const editor = {
    backgroundColor: "lightgray",
    padding: "1rem",
    border: "1px solid #ccc",
  };
  const toolbar = {
    border: "1px solid #ccc",
  };

  const onChangeEdit = () => {
    setHtml(convertToHTML(editorState.getCurrentContent()));
  };

  const onClickCreate = async () => {
    const nd = new Date();
    const mm = ("0" + (nd.getMonth() + 1)).slice(-2);
    const dd = ("0" + nd.getDate()).slice(-2);
    const date = `${nd.getFullYear()}年${mm}月${dd}日 `;

    const file = new FormData();
    file.append("date", date);
    file.append("html", html);
    file.append("title", title);
    file.append("from", from);
    await axios
      .post(NewsHost + "/news", file, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        alert("成功");
      })
      .catch((e) => {
        alert("失敗");
      });
  };

  return (
    <div>
      <p>
        <div>タイトル</div>
        <input
          type={"text"}
          style={{ width: "80%" }}
          onChange={(e) => setTitle(e.target.value)}
        />
      </p>
      <p>
        <div>差出人</div>
        <input
          type={"text"}
          style={{ width: "80%" }}
          onChange={(e) => setFrom(e.target.value)}
        />
      </p>
      <p>
        <div>本文(Shift+EnterまたはAlt+Enterで改行)</div>
        <div style={{ width: "80%", border: "1px solid #000" }}>
          <Editor
            onChange={onChangeEdit}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName={wrapper}
            editorClassName={editor}
            toolbarClassName={toolbar}
          />
        </div>
      </p>

      <Button onClick={(e) => onClickCreate()}>登録</Button>
    </div>
  );
};

export default CreateNews;
