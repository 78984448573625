import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiHost } from "./info";

const Email = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios.get(ApiHost + "/onsen_email").then((res) => {
      if (res.data != "") {
        setData(res.data);
      }
    });
  };
  return (
    <div style={{ height: "90vh", overflow: "scroll",width:"50%" }}>
      {data != "" &&
        data.map((data, i) => (
          <div>
            <b>{data.email}</b>
          </div>
        ))}
    </div>
  );
};

export default Email;
